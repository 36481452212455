<script setup>
import { onMounted, ref, watch } from 'vue';
import { useLayout } from '@/layout/composables/layout';
import { useAuthStore } from "@/store/auth";
import { Link } from '@inertiajs/vue3'
import Wrapper from './Wrapper.vue'

const { layoutConfig } = useLayout();
const price = ref(0);
const chartData = ref(null);
const chartOptions = ref(null);
const assetTransfers = ref([
    { name: 'HQ', to: 'Branch 1', asset: 'TU 800', completed: true, date: '06/04/2022' },
    { name: 'HQ', to: 'Branch 1', asset: 'TU 801', completed: true, date: '07/04/2022' },
    { name: 'HQ', to: 'Branch 1', asset: 'TU 802', completed: false, date: '12/04/2022' },
    { name: 'HQ', to: 'Branch 1', asset: 'TU 803', completed: true, date: '17/04/2022' },
    { name: 'HQ', to: 'Branch 1', asset: 'TU 804', completed: false, date: '20/04/2022' }
]);

onMounted(() => {
    initChart();
});

watch(layoutConfig.colorScheme, () => {
    initChart();
});

const authStore = useAuthStore();

(async() => {
  if (!authStore.userData) {
    await authStore.session()
  }
})();

const session = authStore.userData

const initChart = () => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    chartData.value = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'Active',
                data: [6500, 5900, 8000, 8100, 5600, 5500, 4000],
                fill: false,
                tension: 0.4,
                borderColor: documentStyle.getPropertyValue('--green-500')
            },
            {
                label: 'Dormant',
                data: [1200, 5100, 6200, 3300, 2100, 6200, 4500],
                fill: true,
                borderColor: '#6366f1',
                tension: 0.4,
                backgroundColor: 'rgba(99,102,220,0.2)'
            }
        ]
    };

    chartOptions.value = {
        animation: {
            duration: 0
        },
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }

                        if (context.parsed.y !== null) {
                            label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                        }
                        return label;
                    }
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            }
        }
    };
};

const formatCurrency = (value) => {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};
</script>

<template>
  <Wrapper>
    <div class="grid">
        <div class="col-12">
            <div class="flex flex-column sm:flex-row align-items-center gap-4">
                <div class="flex flex-column sm:flex-row align-items-center gap-3">
                    <img alt="avatar" src="/images/avatar.png" class="w-4rem h-4rem flex-shrink-0" />
                    <div class="flex flex-column align-items-center sm:align-items-start">
                        <span class="text-900 font-bold text-4xl">Welcome {{ session?.identity?.traits?.name?.first }} {{ session?.identity?.traits?.name?.last }}</span>
                        <p class="text-600 m-0"></p>
                    </div>
                </div>
                <div class="flex gap-2 sm:ml-auto">
                  <Button type="button" v-tooltip.bottom="'Exchange'" icon="pi pi-arrows-h" class="p-button-rounded p-button-outlined"></Button>
                    <Button type="button" v-tooltip.bottom="'Withdraw'" icon="pi pi-download" class="p-button-rounded p-button-outlined"></Button>
                    <Button type="button" v-tooltip.bottom="'Send'" icon="pi pi-send" class="p-button-rounded"></Button>
                </div>
            </div>
        </div>

        <!--
        <div class="col-12 md:col-6 xl:col-4">
            <div class="card h-full relative overflow-hidden">
                <svg id="visual" viewBox="0 0 900 600" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" class="absolute left-0 top-0 h-full w-full" preserveAspectRatio="none">
                    <rect x="0" y="0" width="900" height="600" fill="var(--primary-600)"></rect>
                    <path
                        d="M0 400L30 386.5C60 373 120 346 180 334.8C240 323.7 300 328.3 360 345.2C420 362 480 391 540 392C600 393 660 366 720 355.2C780 344.3 840 349.7 870 352.3L900 355L900 601L870 601C840 601 780 601 720 601C660 601 600 601 540 601C480 601 420 601 360 601C300 601 240 601 180 601C120 601 60 601 30 601L0 601Z"
                        fill="var(--primary-500)"
                        strokeLinecap="round"
                        strokeLinejoin="miter"
                    ></path>
                </svg>
                <div class="z-2 relative text-white">
                    <div class="text-xl font-semibold mb-3">Account</div>
                    <div class="mb-1 font-semibold">Balance</div>
                    <div class="text-2xl mb-5 font-bold">$2.000,00</div>
                    <div class="flex align-items-center justify-content-between">
                        <span class="text-lg">**** **** **** 1412</span>
                        <span class="font-medium text-lg">12/26</span>

                    </div>
                </div>
            </div>
        </div>
        -->
        <div class="col-12 md:col-6 xl:col-4">
            <div class="card h-full flex flex-column align-items-center justify-content-center">
                <i class="pi pi-globe text-primary text-4xl mb-4"></i>
                <span class="text-900 text-lg mb-4 font-medium">Trackers</span>
                <span class="text-900 text-2xl text-primary font-bold">501</span>
            </div>
        </div>
        <div class="col-12 md:col-6 xl:col-2">
            <div class="card h-full flex flex-column align-items-center justify-content-center">
                <i class="pi pi-replay text-primary text-4xl mb-4"></i>
                <span class="text-900 text-lg mb-4 font-medium text-center">Unassigned Trackers</span>
                <span class="text-900 text-2xl text-primary font-bold">497</span>
            </div>
        </div>
        <div class="col-12 md:col-6 xl:col-2">
            <div class="card h-full flex flex-column align-items-center justify-content-center">
                <i class="pi pi-globe text-primary text-4xl mb-4"></i>
                <span class="text-900 text-lg mb-4 font-medium">Assets</span>
                <span class="text-900 text-2xl text-primary font-bold">4</span>
            </div>
        </div>
        <div class="col-12 md:col-6 xl:col-2">
            <div class="card h-full flex flex-column align-items-center justify-content-center">
                <i class="pi pi-globe text-primary text-4xl mb-4"></i>
                <span class="text-900 text-lg mb-4 font-medium">Users</span>
                <span class="text-900 text-2xl text-primary font-bold">3</span>
            </div>
        </div>

        <div class="col-12 xl:col-4">
            <div class="card">
                <div class="text-900 text-xl font-semibold mb-3">Journeys</div>
                <ul class="list-none p-0 m-0">
                    <li class="flex align-items-center p-3 mb-3 border-bottom-1 surface-border">
                        <img alt="brands" src="/images/avatar.png" class="w-3rem flex-shrink-0 mr-3" />
                        <div class="flex flex-column">
                            <span class="text-xl font-medium text-900 mb-1">John Citizen</span>
                            <span>Sydney, CBD</span>
                        </div>
                        <span class="text-xl text-900 ml-auto font-semibold">20km<br/>2 Hours</span>
                    </li>
                    <li class="flex align-items-center p-3 mb-3 border-bottom-1 surface-border">
                        <img alt="brands" src="/images/avatar.png" class="w-3rem flex-shrink-0 mr-3" />
                        <div class="flex flex-column">
                            <span class="text-xl font-medium text-900 mb-1">John Citizen</span>
                            <span>Sydney, CBD</span>
                        </div>
                        <span class="text-xl text-900 ml-auto font-semibold">20km<br/>2 Hours</span>
                    </li>
                    <li class="flex align-items-center p-3 mb-3 border-bottom-1 surface-border">
                        <img alt="brands" src="/images/avatar.png" class="w-3rem flex-shrink-0 mr-3" />
                        <div class="flex flex-column">
                            <span class="text-xl font-medium text-900 mb-1">John Citizen</span>
                            <span>Sydney, CBD</span>
                        </div>
                        <span class="text-xl text-900 ml-auto font-semibold">20km<br/>2 Hours</span>
                    </li>
                    <li class="flex align-items-center p-3 mb-3 border-bottom-1 surface-border">
                        <img alt="brands" src="/images/avatar.png" class="w-3rem flex-shrink-0 mr-3" />
                        <div class="flex flex-column">
                            <span class="text-xl font-medium text-900 mb-1">John Citizen</span>
                            <span>Sydney, CBD</span>
                        </div>
                        <span class="text-xl text-900 ml-auto font-semibold">20km<br/>2 Hours</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-12 xl:col-8">
            <div class="card h-full">
                <div class="text-900 text-xl font-semibold mb-3">Deployed Trackers</div>
                <Chart type="line" :data="chartData" :options="chartOptions"></Chart>
            </div>
        </div>


        <div class="col-12 lg:col-12">
            <div class="card">
                <div class="text-900 text-xl font-semibold mb-3">Recent Changes</div>

                <DataTable ref="dt" :value="assetTransfers" dataKey="id" :rows="5" class="datatable-responsive" emptyMessage="No recent transfers." responsiveLayout="scroll">
                    <Column field="name" header="From Site Container" class="white-space-nowrap w-4"> </Column>
                    <Column field="name" header="To Site Container" class="white-space-nowrap w-4"> </Column>
                    <Column field="price" header="Asset" class="white-space-nowrap w-4">
                        <template #body="{ data }">
                            {{ data.asset }}
                        </template>
                    </Column>
                    <Column field="date" header="Date" class="white-space-nowrap w-4"> </Column>
                    <Column field="inventoryStatus" header="Status" class="white-space-nowrap w-4 text-right">
                        <template #body="{ data }">
                            <Tag v-if="data.completed" value="COMPLETED" severity="success"></Tag>
                            <Tag v-else value="PENDING" severity="warning"></Tag>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
  </Wrapper>
</template>
