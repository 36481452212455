import Dexie, { type EntityTable } from 'dexie';
import 'dexie-observable';
import type { InjectionKey } from 'vue';
import type { Table } from 'dexie';

export interface RequestIDTracker {
    current: number;
    incr: () => void;
}
type DeviceOwner = {
    id: string;
    name: string;
};

interface Utilisation {
    id: string;
    imei: string;
    fromTime: Date;
    // null if event is ongoing.
    untilTime?: Date;

    // mostly relevant for ongoing events.
    lastCheckedTime: Date;
    voltageThreshold: number;
}

export interface Coordinator {
    id: string;
	serial: string;
    timestamp: Date;
    longitude: string;
    latitude: string;
    voltage: number;
	speed: number;
	angle: number;
}

export interface Device {
    id: string;
    name: string;
    status: string;
    type: string;
    online: boolean;
    lat: string;
    lon: string;
    battery?: number;
    voltage_threshold?: number;
    util?: number;
    shared: boolean;
    owner?: DeviceOwner | null;
    IMEI?: string;
}

export interface Location {
    id: string;
    //device_id: string
    timestamp: string;
    latitude: string;
	longitude: string;
}

export interface Pointer {
	id: string
	timestamp: Date
	container?: string
	version: string
}

export const AssetTypeNone = 'TYPE_NONE';
export const AssetTypeLightTower = 'TYPE_LIGHT_TOWER';
export const AssetTypeGenerator = 'TYPE_GENERATOR';
export const AssetTypeFuelTruck = 'TYPE_FUEL_TRUCK';
export const AssetTypeCar = 'TYPE_CAR';
export const AssetTypeOther = 'TYPE_OTHER';

export type AssetType = typeof AssetTypeNone | typeof AssetTypeLightTower | typeof AssetTypeGenerator | typeof AssetTypeFuelTruck | typeof AssetTypeCar | typeof AssetTypeOther;

export const AssetTypeMapping = {
	[AssetTypeNone]: "",
	[AssetTypeLightTower]: "Light Tower",
	[AssetTypeGenerator]: "Generator",
	[AssetTypeFuelTruck]: "Fuel Truck",
	[AssetTypeCar]: "Car",
	[AssetTypeOther]: "",
};
export interface AssetTableItem {
    id: string;
    name: string;
    hardware_id: string;
    imei: string;
    type: AssetType;
    container_id: string;
    obd: boolean;
    vin: string;
    voltage_min: number;
    voltage_max: number;
    voltage_threshold: number;
    mutation_stamp: Date;

	// moving = vehicles, not machinery.
	flag_moving?: boolean;
	flag_powered?: boolean;
	flag_low_voltage?: boolean;
	flag_stale_data?: boolean;
	// running = utilisation based assets.
	flag_running?: boolean;
	flag_on_hire?: boolean;
	flag_service_overdue?: boolean;
}

export class TrackThisStore extends Dexie {
	coordinator!: EntityTable<Coordinator, 'id'>;
    utilisation!: Table<Utilisation>;
	assets!: EntityTable<AssetTableItem, 'id'>;
	pointers!: EntityTable<Pointer, 'id'>;

    constructor() {
        super('trackthis');
        this.version(22).stores({
            utilisation: '++id, imei',
            coordinator: '++id, serial, imei', // serial not imei plz.
			pointers: '++id, purpose',
            assets: 'id, hardware_id, container_id, type, imei'
		})//.upgrade(trans => trans.table('pointers').clear())
    }
}

export const db = new TrackThisStore();

export const DB_KEY: InjectionKey<TrackThisStore> = Symbol('db.db');
