<script setup lang="ts">
import Wrapper from "@/pages/management/Wrapper.vue";
import { computed, ref, toRef, watch, inject } from "vue";
import { SyncWorker } from "@/plugins/sync";
import type { Synchroniser } from "@/plugins/sync";
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import Chip from 'primevue/chip'
import ContainerTreeSelect from "@/components/ContainerTreeSelect.vue";
import { useToast } from "primevue/usetoast";
import { useAuthStore } from "@/store/auth";
import { Link } from '@inertiajs/vue3'
import { useUrlSearchParams } from '@vueuse/core'
import { AssetTypeMapping } from "@/db";

const authStore = useAuthStore();

const synchroniser = inject<Synchroniser>(SyncWorker)

interface ContainerItemV2 {
    id: string;
    name: string;
    asset_count: number;
    parent_container_id?: string;
}
type NewContainerlist = {[key: string]: ContainerItemV2}

interface AnAsset {
	id: string;
	name: string;
	container_id: string;
	container_name: string;
	is_installed ?: boolean;
	tracker_serial?: string;
	linked_id?: string;
	type: string;
}

const props = defineProps<{
  assets: [],
  containers: NewContainerlist
}>()
const containerItems = toRef(props, 'containers');
const assets = toRef(props, 'assets');

const toast = useToast();

const filters = ref();
const initFilters = () => {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
	  is_installed: { value: true, matchMode: FilterMatchMode.EQUALS }
  };
};

const changeSite = (id: string, index: number) => {
  if (id in enableChangingSite.value) {
    enableChangingSite.value[id] = !enableChangingSite.value[id]
  } else {
    enableChangingSite.value[id] = true
  }
}

initFilters();

const wait = async (milliseconds) => new Promise(resolve => setTimeout(resolve, milliseconds));

const clearFilter = () => {
  initFilters();
};

const params = useUrlSearchParams('history')

// ?keyword=TTKMK... will apply global search filter
watch(() => params.keyword,
    (qs) => {
        if (!qs?.length) {
            return
        }

        filters.value.global.value = qs
}, {immediate: true})

const enableChangingSite = ref({});
const enableRenaming = ref({});
const selectedSite = ref({});
const saving = ref({});

const newAssetName = ref({})

const rename = (id: string, index: number, currentName: string) => {
	newAssetName.value[id] = currentName

	if (id in enableRenaming.value) {
		enableRenaming.value[id] = !enableRenaming.value[id]
	} else {
		newAssetName[id] = currentName
		enableRenaming.value[id] = true
	}
}

const saveSiteChange = async (data: AnAsset) => {
	saving.value[data.id] = true;
	const siteId = Object.getOwnPropertyNames(selectedSite?.value?.[data.id] || {})?.[0]
	if (!siteId) {
		return
	}

	const rawResponse = await fetch("?form=site", {
		method: "POST",
			credentials: "include",
			headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify({
			"id": data.id,
			"site": siteId
		})
	});
	saving.value[data.id] = false;

	if (rawResponse.ok) {
		await rawResponse.json()
		enableChangingSite.value[data.id] = false

		data.container_name = containerItems.value[siteId].name
		toast.add({
			severity: "info",
			summary: "Asset site has changed"
		})

		await wait(500)
		synchroniser.resync()
	}
}

const saveRename = async(data: AnAsset) => {
	saving.value[data.id] = true;

	const oldName = data.name

	const rawResponse = await fetch("?form=name", {
		method: "POST",
		credentials: "include",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify({
			"id": data.id,
			"newName": newAssetName.value[data.id]
		})
	});

	saving.value[data.id] = false;

	if (rawResponse.ok) {
		await rawResponse.json()

		enableRenaming.value[data.id] = false
		// @ts-ignore
		data.name = newAssetName.value[data.id]

		toast.add({
			severity: "info",
			summary: `Asset '${oldName}' has been renamed to '${newAssetName.value[data.id]}'`
		})

		await wait(500)
		synchroniser.resync()
	}
}

const linkToMap = (ttSerial: string) => {
    return `/map/${ttSerial}`
}

const linkToTrackerList = (ttSerial: string) => {
    return `/management/trackers?keyword=${ttSerial}`
}

const isAdmin = computed(() => authStore?.userData?.identity?.metadata_public?.role === "administrator");
</script>
<template>
  <Wrapper id="assetlist">
    <h1>Assets</h1>
    <DataTable v-model:filters="filters" filterDisplay="menu" data-key="id" sortField="name" :sortOrder="1"
               :globalFilterFields="['name', 'tracker_serial']"
               :value="assets" paginator :rows="10" :rowsPerPageOptions="[10, 20, 50]" tableStyle="width: 100%">
      <template #header>
        <div class="flex justify-content-between">
          <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined @click="clearFilter()" />
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
        </span>
        </div>
      </template>
		<Column header="Name" field="name" :showFilterMatchModes="false" style="" sortable>
			<template #body="{ data, index }">
				<span v-if="!enableRenaming[data.id]">{{ data.name }}</span>
				<div v-if="enableRenaming[data.id]" class="inline-flex align-items-center">
					<InputText type="text" v-model="newAssetName[data.id]" placeholder="Default"></InputText>

					<Button type="button" class="" label="" icon="pi pi-check" :loading="saving[data.id]" @click="saveRename(data)" />
				</div>
			</template>
		</Column>
		<Column header="Type" :showFilterMatchModes="false" field="type" sortable>
			<template #body="{ data, index }">
				{{ AssetTypeMapping[data.type] }}
			</template>
		</Column>

        <Column header="Site" :showFilterMatchModes="false" style="">
            <template #body="{ data, index }">
              <div v-if="enableChangingSite[data.id]" class="inline-flex align-items-center">
                <ContainerTreeSelect v-model="selectedSite[data.id]" :single="true" placeholder="None selected" :containers="containerItems" />
                <Button type="button" class="" label="" icon="pi pi-check" :loading="saving[data.id]" @click="saveSiteChange(data)" />
              </div>

              <Chip v-if="data.container_name && !enableChangingSite[data.id]" :label="data.container_name" />
            </template>
        </Column>
		<Column header="Hardware" :showFilterMatchModes="false" style="">
			<template #body="{ data, index }">
				<Link :href="linkToTrackerList(data.tracker_serial)">
					<Chip icon="pi pi-external-link" v-if="data.tracker_serial" :label="data.tracker_serial" />
				</Link>
			</template>
		</Column>
		<Column header="Connector" field="linked_id" :showFilterMatchModes="false">
			<template #body="{ data, index }">
				<Chip v-if="data.linked_id" icon="pi pi-sync" :label="'Syrinx > ' + data.linked_id" />
			</template>
		</Column>
        <Column header="Actions" style="width: auto">
        <template #body="{ data, index }">
			<Button ref="popup" v-if="isAdmin" :disabled="enableChangingSite[data.id]" @click="changeSite(data.id, index)" icon="pi pi-home" label="Change Site" class="mr-2"></Button>
            <Link preserve-state :href="linkToMap(data.tracker_serial)" class="font-bold border-round">
                <Button ref="popup" icon="pi pi-map" label="Map" class="mr-2"></Button>
            </Link>
			<Button ref="popup" v-if="isAdmin" :disabled="enableRenaming[data.id]" @click="rename(data.id, index, data.name)" icon="pi pi-home" label="Rename" class="mr-2"></Button>
          <!--<Button v-if="!data.asset_id" icon="pi pi-pencil" outlined rounded class="mr-2" label="Assign" />-->
        </template>
      </Column>
    </DataTable>
  </Wrapper>
</template>
<style lang="scss">
#assetlist .p-datatable table {
	table-layout: auto !important;
}
</style>
