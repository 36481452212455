<script lang="ts" setup>
import { computed, inject, onBeforeMount, onBeforeUnmount, ref, toRef, watchEffect } from 'vue';
import { mapSymbol } from '@/types';
import type { Position } from 'geojson';
import type { GeoJSONSource, LngLatBoundsLike, LngLatLike } from 'maplibre-gl';
import { bbox } from '@turf/turf';
import MapPopupGeneric from '@/components/MapPopupGeneric.vue';
import { DateTime } from 'luxon';
import type { AssetType } from '@/db';

interface TripResponse {
    Path: { coordinates: Array<LngLatLike>; distance_meters: number };
    TripFrom: string;
    TripUntil: string;
}

const props = defineProps<{
    trip: TripResponse;
    assetType: AssetType;
	ttid: string;
}>();

const trip = toRef(props, 'trip');
const map = inject(mapSymbol);
const tripPath: { path: Array<LngLatLike> } = { path: [] };

const tripFrom = computed(() => {
	return DateTime.fromISO(trip.value.TripFrom);
});

const tripUntil = computed(() => {
	return DateTime.fromISO(trip.value.TripUntil);
});

watchEffect(
    async () => {
        if (!trip.value) {
            console.log('TRIP DESELECTED');
            // Trip deselected?
        } else {
            console.log(trip.value);
            tripPath.path = trip.value.Path.coordinates;
            const src = map.value.getSource('trip-path') as GeoJSONSource;
            if (!src) {
                console.log('SKIPP');
                return;
            }

            src.updateData({
                update: [
                    {
                        id: 'main',
                        newGeometry: {
                            type: 'LineString',
                            coordinates: trip.value.Path.coordinates as Position[]
                        }
                    }
                ]
            });

            var tripBounds = bbox(await src.getData()) as LngLatBoundsLike;
            map.value.fitBounds(tripBounds, {
                screenSpeed: 4,
                maxZoom: 16
            });

            startCoords.value = tripPath.path[0] as LngLatLike;
            endCoords.value = tripPath.path[tripPath.path.length - 1] as LngLatLike;

            //map.value.redraw()
            console.log(trip.value);
        }
    },
    { flush: 'post' }
);

onBeforeUnmount(() => {
    map.value.removeLayer('lines');
    map.value.removeLayer('directions');
    //map.value.removeLayer('trip-view')
    map.value.removeSource('trip-path');
});

const startCoords = ref<LngLatLike>();
const endCoords = ref<LngLatLike>();

onBeforeMount(async () => {
    // Possibly redundant. If watchers activate before this.
    console.log('@@@@@@@@@ MapTripView ONMOUNTED');

    // IF STATIC ASSET, USE BOUNDING BOX CENTER
    map.value.addSource('trip-path', {
        type: 'geojson',
        data: {
            type: 'Feature',
            properties: {},
            id: 'main',
            geometry: {
                type: 'LineString',
                coordinates: [] as Position[]
            }
        }
    });

    /*
	map.value.addLayer({
		'id': 'trip-view',
		'type': 'line',
		'source': 'trip-path',
		'layout': {
			'line-join': 'round',
			'line-cap': 'round'
		},
		'paint': {
			'line-color': '#888',
			'line-width': 8
		}
	});
*/

    map.value.addLayer({
        id: 'lines',
        source: 'trip-path',
        type: 'line',
        paint: {
            'line-width': 4
        }
    });

    map.value.addLayer({
        id: 'directions',
        type: 'symbol',
        source: 'trip-path',
        paint: {},
        layout: {
            'symbol-placement': 'line',
            'icon-image': 'yotea-follow',
            'icon-size': 0.4,
            'icon-rotate': 90,
            'icon-rotation-alignment': 'map',
            'icon-allow-overlap': true,
            'icon-ignore-placement': true
        }
    });
});
</script>

<template>
    <MapPopupGeneric v-if="startCoords" :coords="startCoords">
        <table class="w-full" :style="{ borderCollapse: 'collapse', tableLayout: 'auto' }">
            <thead>
                <tr>
                    <th class="text-left font-semibold py-3 border-bottom-1 surface-border white-space-nowrap">Started</th>
                    <th class="text-right font-semibold py-3 border-bottom-1 surface-border white-space-nowrap px-3">
                        {{ tripFrom.toLocaleString(DateTime.DATETIME_MED) }}
                    </th>
                </tr>
            </thead>
        </table>
    </MapPopupGeneric>
    <MapPopupGeneric v-if="endCoords" :coords="endCoords" :closable="false">
        <table class="w-full" :style="{ borderCollapse: 'collapse', tableLayout: 'auto' }">
            <thead>
                <tr>
                    <th class="text-left font-semibold py-2 border-bottom-1 surface-border white-space-nowrap">Stopped</th>
                    <th class="text-right font-semibold py-2 border-bottom-1 surface-border white-space-nowrap px-3">
                        {{ tripUntil.toLocaleString(DateTime.DATETIME_MED) }}
                    </th>
                </tr>
                <tr>
                    <th class="text-left font-semibold py-2 border-bottom-1 surface-border white-space-nowrap">Distance</th>
                    <th class="text-right font-semibold py-2 border-bottom-1 surface-border white-space-nowrap px-3">{{ trip.Path.distance_meters / 1000 }}km (approx)</th>
                </tr>
            </thead>
        </table>
    </MapPopupGeneric>
</template>
