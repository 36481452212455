<script setup>
//import AppBreadcrumb from './AppBreadcrumb.vue';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useLayout } from '@/layout/composables/layout';
import Silhouette from "@/assets/image/silhouette.png";

const { onMenuToggle, onProfileSidebarToggle } = useLayout();

const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);

onMounted(() => {
    bindOutsideClickListener();
});

onBeforeUnmount(() => {
    unbindOutsideClickListener();
});

const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                topbarMenuActive.value = false;
            }
        };
        document.addEventListener('click', outsideClickListener.value);
    }
};
const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener);
        outsideClickListener.value = null;
    }
};
const isOutsideClicked = (event) => {
    if (!topbarMenuActive.value) return;

    const sidebarEl = document.querySelector('.layout-topbar-menu');
    const topbarEl = document.querySelector('.layout-topbar-menu-button');

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};

const showProfileSidebar = () => {
    onProfileSidebarToggle();
};
</script>

<template>
    <div class="layout-topbar absolute z-5" style="top:0; right: 0;">
        <div class="topbar-start">
            <button type="button" class="topbar-menubutton p-link p-trigger" @click="onMenuToggle" v-if="false">
                <i class="pi pi-bars"></i>
            </button>

            <!--<AppBreadcrumb class="topbar-breadcrumb"></AppBreadcrumb>-->
        </div>

		<ul class="topbar-menu">
                <!--<Button icon="pi pi-box" class="flex-1" label="Add new tracked asset"></Button>


                <li class="topbar-search">
                    <span class="p-input-icon-left">

                        <i class="pi pi-search"></i>
                        <InputText type="text" placeholder="Search for asset" class="w-12rem sm:w-12rem" />
                    </span>
                </li>
                -->
			<li class="topbar-profile">
				<button type="button" class="p-link p-avatar-circle" @click="showProfileSidebar"><img :src="Silhouette" alt="Profile" style="" /></button>
			</li>
		</ul>

		<div v-if="false" class="topbar-end"> </div>
    </div>
</template>

<style lang="scss" scoped></style>
