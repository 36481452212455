<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import { useFilterScopeStore } from "@/store/filterscope";

const filterState = useFilterScopeStore();
const machineTypeFilter = ref(filterState.assetTypes);

watchEffect(() => {
	filterState.assetTypes = machineTypeFilter.value
})

const assetTypes = ref([
	{ icon: 'pi pi-fw pi-sun', name: 'Light Tower', code: 'TYPE_LIGHT_TOWER' },
	//{ icon: 'pi pi-briefcase', name: 'Traffic Management Attentuator', code: 'TYPE_TMA' },
	//{ icon: 'pi pi-briefcase', name: 'Traffic Management Unit', code: 'TYPE_TMU' },
	{ icon: 'pi pi-briefcase', name: 'Fuel Truck', code: 'TYPE_FUEL_TRUCK' },
	{ icon: 'pi pi-bolt', name: 'Generator', code: 'TYPE_GENERATOR' }
]);

</script>

<template>

	<div class="p-dataview-layout-options p-selectbutton p-buttonset" role="group">
		<div id="sfilters" class="flex-grow flex align-items-left justify-content-left">
			<MultiSelect :scrollHeight="'350px'" option-value="code" id="ms-cities" v-model="machineTypeFilter" :showToggleAll="false" :options="assetTypes" optionLabel="name" placeholder="Filter by Asset Type" display="chip" class="w-full md:w-20rem">
				<template #option="slotProps">
					<div class="flex align-items-center">
						<span :class="[slotProps.option.icon, 'mr-2']" class="icon"></span>
						<div>{{ slotProps.option.name }}</div>
					</div>
				</template>
			</MultiSelect>
		</div>
	</div>
</template>

<style lang="scss">
#sfilters .p-button {
    padding: 0.75rem 0.7rem !important;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
	background: #0b213f !important;
}
</style>
