import type { InjectionKey, Ref, ShallowRef } from 'vue';
import type { Map, FlyToOptions, Marker, Popup } from 'maplibre-gl';

export interface ActiveAsset {
    serial: string;
    IMEI: string;
}

export interface Device {
    id: string;
    name: string;
    branch?: string;
    status: string;
    type: string;
    online: boolean;
    lat: string;
    voltage_threshold?: number;
    lon: string;
    battery?: number;
    util?: number;
    voltage?: number;
    shared: boolean;
    owner?: DeviceOwner | null;
    timestamp?: string;
    IMEI?: string;
    container?: string;
}

export interface DeviceCollection {
    Devices: Device[];
}

export type DeviceOwner = {
    id: string;
    name: string;
};

export interface ReportResult {
    fromDate: string;
    toDate: string;
    rows: Row[];
    daysTotal: number;
    reportTime: string;
}

export interface AssetSummary {
    imei: string;
    assetName: string;
    assetSerial: string;
    grandTotalSeconds: number;
}

export interface ReportPerDayResult {
    assetSummary: AssetSummary[];
    daysTotal: number;
    fromDate: string;
    toDate: string;
    dates: DateResult[];
    reportTime: string;
}

export interface ServicingReportResult {
	assets: ServicingReportResultItem[]
}

export interface ServicingReportResultItem {
	assetId: string
	assetName: string
	assetType: string
	contractId: string
	lastServicedAt: string
	lastServicedHours: number
	currentEstimatedHours: number
	ttHoursOnLastMeterRead: number
	dueAtHours: number
	site: string
}

export interface DateResult {
    date: string;
    rows: DateRow[];
    grandTotalSeconds: number;
}

export interface DateRow {
    imei: string;
    assetName: string;
    assetSerial: string;
    totalPercentage: number;
    totalSeconds: number;
}

export interface Row {
    imei: string;
    assetName: string;
    totalPercentage: number;
    totalMinutes: number;
    ignitionCount: number;
    averageMinutesSession: number;
    averageMinutesDay: number;
}

//export type MapInstance = InstanceType<typeof Mapbox.Map>

export interface MapController {
    addMarker(marker: Marker);
}

export const $MapInstance: InjectionKey<MapController> = Symbol('$MapInstance') as InjectionKey<MapController>;
export const mapSymbol           = Symbol('map') as InjectionKey<ShallowRef<Map | undefined>>;

export const isLoadedSymbol = Symbol('isLoaded') as InjectionKey<Ref<boolean>>;
export const isInitializedSymbol = Symbol('isInitialized') as InjectionKey<Ref<boolean>>;
export const $Popup: InjectionKey<Popup> = Symbol(`$Popup`) as InjectionKey<Popup>;

export interface CalibratedVoltages {
    current: CalibratedVoltage;
    all: CalibratedVoltage[];
    imei: string;
}

export interface CalibratedVoltage {
    at_time: AtTime;
    median: number;
    lower: number;
    upper: number;
}

export interface AtTime {
    seconds: number;
    nanos: number;
}
