import type { AssetType } from '@/db';

export const isVehicle = (type: AssetType): boolean => {
    if (type == 'TYPE_LIGHT_TOWER') {
        return false;
    }

    if (type == 'TYPE_GENERATOR') {
        return false;
    }

    return true;
};
