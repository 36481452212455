<script setup lang="ts">
import { ref } from 'vue';
import AppConfig from '@/layout/AppConfig.vue';
import { useAuthStore } from './../../store/auth';
import { Link } from '@inertiajs/vue3'
import { useToast } from 'primevue/usetoast';
import { db } from "@/db";

const toast = useToast();

const userStore = useAuthStore();
//import router from '@/router/';
const router = {
  currentRoute: {
    value: {
      query: {
        goto: ""
      }
    }
  },
  push: async (obj: any) => {},
}

const email = ref('');
const password = ref('');
const loading = ref(false);
const loginError = ref('');

let urlParams = new URLSearchParams(window.location.search);

if (urlParams.has('pwupdated')) {
  setTimeout(() => toast.add({severity: "info", detail: "Your new password has been set."}), 500)
}

(async() => {
  await userStore.session()
  if (userStore.userData) {
    window.location.href = 'map';
  }
})()

const navigateToDashboard = async (e: Event) => {
    e.preventDefault();

    loading.value = true;
    const yers = await userStore.login(email.value, password.value)

    loading.value = false;
    if (yers.ok) {
        window.location.href = 'map';

        // Refresh session for store state
        //await userStore.session()
        //if ('goto' in route.query) {
       //     await router.push({ path: route.query?.goto as string });
       // } else {
       //     await router.push({ name: 'overview' });
        //}
    } else {
        if (yers?.content?.error?.code == 401) {
            loginError.value = yers?.content?.error?.reason
        } else {
            const loginResponseMessage = yers?.content?.ui?.messages?.[0]

            if (loginResponseMessage?.type === 'error') {
                loginError.value = loginResponseMessage.text
            }
        }
    }
}
</script>

<template>
  <Toast />
  <div class="px-5 min-h-screen flex justify-content-center align-items-center">
        <div class="border-1 w-40rem surface-border surface-card border-round py-7 px-4 md:px-7 z-1">
            <div class="mb-4">
                <div class="text-900 text-xl font-bold mb-2">Login to your account</div>
                <span class="text-600 font-medium">Please enter your details</span>
            </div>
            <div class="flex flex-column">
                <form @submit="navigateToDashboard">
                    <span class="p-input-icon-left w-full mb-4">
                        <i class="pi pi-envelope"></i>
						<InputText id="email" v-model="email" :class="{'p-invalid': loginError}" type="email" class="w-full md:w-25rem" placeholder="Email Address" />
                    </span>
                    <span class="p-input-icon-left w-full mb-4">
                        <i class="pi pi-lock"></i>
						<InputText id="password" v-model="password" autocomplete="current-password" :class="{'p-invalid': loginError}" type="password" class="w-full md:w-25rem" placeholder="Password" />
                    </span>


                    <div class="mb-4 flex flex-wrap gap-3">
                        <!--<div>
                            <Checkbox name="checkbox" v-model="rememberMe" binary class="mr-2"></Checkbox>
                            <label htmlFor="checkbox" class="text-900 font-medium mr-8"> Remember Me </label>
                        </div>
                        -->
                        <Link class="text-600 cursor-pointer hover:text-primary cursor-pointer transition-colors transition-duration-300" href="/recover">Forgot password</Link>
                    </div>

                    <div v-if="loginError" class="my-5 white-space-normal" style="width:100%">
                        <InlineMessage severity="error">{{ loginError }}</InlineMessage>
                    </div>

                    <Button :loading="loading" type="submit" label="Log In" class="w-full"></Button>
                </form>
            </div>
        </div>
    </div>
    <AppConfig simple />
</template>

<style>
.w-40rem {
    width:460px;
}
</style>
