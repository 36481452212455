<script setup>
import { ref, onMounted } from 'vue';

onMounted(() => {
  products.value = [
    {
      name: "Garry",
      email: "eggs@soup.com",
      invited_by: "Garry",
      activity: "4 days ago"
    }
  ]
});

const products = ref();

</script>

<template>
  <div class="card">
    <DataTable :value="products" tableStyle="min-width: 50rem">
      <Column field="name" header="Name"></Column>
      <Column field="email" header="Email"></Column>
      <Column field="invited_by" header="Invited By"></Column>
      <Column field="activity" header="Activity"></Column>
    </DataTable>
  </div>
</template>
