<script setup lang="ts">
import type { TreeNode } from "primevue/tree";
import { computed, inject, ref, shallowRef, toRaw, toRef, toRefs, watch, watchEffect } from "vue";
import type { Ref } from "vue";
import * as yup from "yup";
import { ErrorMessage } from "vee-validate";
import { useToast } from "primevue/usetoast";
import { useIntervalFn } from '@vueuse/core'
import Wrapper from "@/pages/management/Wrapper.vue";
import { Config } from "@/utils/build";
import { router } from '@inertiajs/vue3'
import { useAuthStore } from "@/store/auth";

type NewContainerlist = { [key: string]: ContainerItem }

interface ContainerItem {
    id: string;
    name: string;
    parent_container_id: string;
}

const configuration = inject(Config);
const selectedTreeTableValue = ref("");
const toast = useToast();

const props = defineProps<{
    containers: NewContainerlist
    warning?: string
    role: string
}>();

const authStore = useAuthStore();

const { containers } = toRefs(props);

if (props.warning) {
    const { pause, resume, isActive } = useIntervalFn(() => {
        router.visit('', { method: 'get' })
    }, 30000)
}

const loadNewContainers = (newTree: Ref<NewContainerlist>) => {
    const cNewCopy = JSON.parse(JSON.stringify(toRaw(newTree.value)));

    //const nodes = Array<TreeNode>()
    const nodes = new Map<string, TreeNode>();
    let rootNode: TreeNode;

    for (const cntnID of Object.keys(cNewCopy)) {
        if (!cNewCopy[cntnID].hasOwnProperty('parent_container_id') || cNewCopy[cntnID].parent_container_id == "") {
            rootNode = {
                key: cNewCopy[cntnID].id,
                data: {
                    name: cNewCopy[cntnID].name,
                    size: "~",
                    actions: "",
                    asset_count: cNewCopy[cntnID]?.asset_count,
                },
                label: cNewCopy[cntnID].name,
                children: []

            } as TreeNode;
            nodes.set(cNewCopy[cntnID].id, rootNode);
            delete cNewCopy[cntnID];
        }
    }

    let i = 0;

    while (Object.values(cNewCopy).length > 0) {
        if (Object.values(cNewCopy).length == 0) {
            break;
        }

        i++;
        if (i > 1000) {
            console.log("unsafe break");
            break;
        }

        for (const cntnID of Object.keys(cNewCopy)) {
            const parent = nodes.get(cNewCopy[cntnID].parent_container_id);
            if (parent) {
                const newNode = {
                    key: cNewCopy[cntnID].id,
                    data: {
                        name: cNewCopy[cntnID].name,
                        asset_count: cNewCopy[cntnID]?.asset_count,
                        actions: ""
                    },
                    label: cNewCopy[cntnID].name,
                    children: []
                } as TreeNode;

                nodes.set(cntnID, newNode);
                parent.children.push(newNode);
                delete cNewCopy[cntnID];
            }

        }
    }

    return [rootNode];
};

// NEW

let containerNodes = ref<TreeNode[]>([]);

//const newTree = ref<TreeNode[]>([])
const renderNodes = () => {
    containerNodes.value = loadNewContainers(containers);
}

renderNodes();

const selectedValue = shallowRef(null);

const schema = yup.object({
    newSiteName: yup.string().required().min(3),
    selectedValue: yup.string().required()
});

// relativePath, id
const loadingResponse = ref(false);

/*let siteTreeWithRoot = ref({
    key: "/",
    label: configuration.CompanyName,
    children: tree
}) as Ref<TreeNode>*/

const visible = ref(false);
const newSiteName = ref("");

function convertToSlug(Text) {
    return Text.replace(/[^\w ]+/g, "").replace(/ +/g, "_");
}


const createSite = async (e) => {
    e.preventDefault();

    loadingResponse.value = true;

    const slug = convertToSlug(newSiteName.value);
    const pathPrefix = Object.keys(toRaw(selectedValue).value)[0]!;

    const rawResponse = await fetch("", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "friendlyName": newSiteName.value,
            "targetContainerId": pathPrefix,
            "form": "add-site"
        })
    });

    if (rawResponse.ok) {
        visible.value = false;
        //siteTree.value = loadContainers();
        loadingResponse.value = false;

        const newItem = await rawResponse.json();
        containers.value[newItem["containerId"]] = {
            id: newItem["containerId"],
            name: newSiteName.value,
            parent_container_id: pathPrefix
        };

        renderNodes();
        newSiteName.value = "";
        selectedValue.value = undefined;

        return;
    } else {
        toast.add({
            severity: "error",
            summary: "Create new site",
            detail: `Could not add site "${newSiteName.value}"`,
            life: 1000 * 28
        });
    }

    loadingResponse.value = false;
};

const isAdmin = computed(() => authStore?.userData?.identity?.metadata_public?.role === "administrator");
</script>

<template>
    <Wrapper>
		<Toast />
        <Message :closable="false" v-if="props.warning" severity="warn">{{ props.warning }}</Message>

        <!-- TODO: Close dialog = confirmation, if has input value -->
        <Dialog v-model:visible="visible" modal header="Create new Site" :style="{ width: '50vw' }">
            <form @submit="createSite" :validation-schema="schema">
                <input type="hidden" name="add-site" value="1" />
                <div class="card p-fluid">
                    <div class="field">
                        <label for="name">Name</label>
                        <InputText v-model="newSiteName" type="text" />

                        <ErrorMessage name="newSiteName" />
                    </div>
                    <div class="field">
                        <label for="parent">Site</label>
                        <TreeSelect v-model="selectedValue" selection-mode="single" :options="containerNodes"
                                    placeholder="Select Item"
                                    :class="['md:w-20rem w-full']" />
                        <ErrorMessage name="selectedValue" />
                    </div>
                </div>
                <Button label="Create" :disabled="loadingResponse" type="submit" class="w-full mb-4"></Button>
            </form>
        </Dialog>

        <div class="grid">
            <div class="col-10">
                <h1>Sites</h1>
            </div>
            <div class="col">
                <Button label="Add" class="w-full mb-4" @click="visible = true" v-if="isAdmin"></Button>
            </div>
        </div>

        <div v-if="role == 'user'">
            You do not have permission to see this page.
        </div>

        <TreeTable v-if="role != 'user'" :value="containerNodes?.[0]?.children" selectionMode="single" v-model="selectedTreeTableValue">
            <Column field="name" header="Name" :expander="true"></Column>
            <Column field="asset_count" header="Assets"></Column>
            <Column field="actions" header="Actions"></Column>
        </TreeTable>

    </Wrapper>
</template>

<style>key
.p-treeselect-panel {
    z-index: 999999 !important;
}
</style>
