import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { ref } from 'vue';
import type { AssetTableItem, Coordinator } from '@/db';
import type { Feature, FeatureCollection, Geometry } from 'geojson';
import { useLatestPositionsStore } from '@/store/positions';

export const useSelectAssetStore = defineStore('selected-asset', () => {
    // TODO: Convert into ShallowRef. Removing the top level ref seems to break reactivity.
    const latestCoordinates: Ref<Coordinator> = ref(null);
    const assetDefinition: Ref<AssetTableItem> = ref(null);

    function selectAsset(asset: AssetTableItem): void {
        assetDefinition.value = asset;
    }

    function getCoordinates(): Ref<Coordinator> {
        const latestPosStore = useLatestPositionsStore();
		if (!assetDefinition.value) {
			return ref();
		}

        return latestPosStore.allPositions.get(assetDefinition.value.imei);
    }

    function reset() {
        assetDefinition.value = null;
        latestCoordinates.value = null;
    }

    return { assetDefinition, selectAsset, getCoordinates, reset };
});
