import type { InjectionKey, Plugin } from "vue";
import type { Map as Maplibre } from 'maplibre-gl';
import type * as MapComponent from "@/components/MapLibre.vue";
import { reactive } from "vue";

export const injectionKey: InjectionKey<MapRegistry> = Symbol()

export interface ActiveMap {
  component?: InstanceType<typeof MapComponent.default>
  map?: Maplibre
  isMounted: boolean
  isLoaded: boolean
}

const instances  = new Map<symbol, ActiveMap>()
const defaultKey = Symbol('default')

interface MapRegistry {
  get(): ActiveMap
  register(instance: InstanceType<typeof MapComponent.default>): ActiveMap
}

const getActiveMap = (): ActiveMap => {
  let component = instances.get(defaultKey);
  if (!component) {
    component = reactive({isLoaded: false, isMounted: false})
    instances.set(defaultKey, component)
  }

  return component
}

const register = (instance: InstanceType<typeof MapComponent.default>): ActiveMap => {
  let component = instances.get(defaultKey);

  if (!component) {
    component = reactive({isLoaded: false, isMounted: false})
    instances.set(defaultKey, component)
  }

  component.isMounted = false
  component.component = instance
  // @ts-ignore
  component.map = instance.map as Maplibre
  // @ts-ignore
  component.isLoaded = (instance.map as Maplibre)?.loaded() || false;

  return component
}

export const MapRegistry: Plugin = {
  async install(app) {
    app.provide(injectionKey, {
      get: getActiveMap,
      register: register,
    })
  },
}