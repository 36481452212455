<script setup lang="ts">
import {watch, ref, toRef, watchEffect, computed} from 'vue';
import { DateTime, Duration } from "luxon";
import type { AssetTableItem } from "@/db";
import AppConfig from "@/layout/AppConfig.vue";
import { toAbsHumanDuration } from "@/utils/time";
import {Link, router} from "@inertiajs/vue3";
import {isVehicle} from "@/utils/asset-types";
import type {TripResponse} from "@/utils/trip";

const props = defineProps<{
	selected?: AssetTableItem | null
	selectedTrip?: TripResponse
}>();


const selectedAsset = toRef(props, "selected");
const selectedTrip = toRef(props, "selectedTrip");
interface Time {seconds: number};

interface Trip {
	started_at: Time
	finished_at: Time
	minutes: number
	finalised: boolean
}

interface RecentTrip {
	started_at: DateTime
	finished_at: DateTime
	duration: Duration
	finalised: boolean
}

const selectedRow = ref();
let init = false;

const recentTrips = ref<RecentTrip[]>([]);

watch(selectedAsset, () => {
	fetch("/util", {
		method: "POST",
		credentials: "include",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify({
			"imei": selectedAsset.value.imei,
		})
	}).then(response => response.json())
	.then(data => {
		const allTrips: RecentTrip[] = []
		for (const t of data?.trips ?? []) {
			allTrips.push({
				started_at: DateTime.fromSeconds(t.started_at.seconds),
				finished_at: DateTime.fromSeconds(t.finished_at.seconds),
				duration: Duration.fromObject({minutes:t.minutes}),
				finalised: t.finalised,
			} as RecentTrip)
		}

		// Hack for selecting row.
		if (!init && selectedTrip.value) {
			const initSelectedFromDate = DateTime.fromISO(selectedTrip.value.TripFrom)
			const plsToSelect = allTrips.filter(i => i.started_at.equals(initSelectedFromDate))?.[0]
			if (plsToSelect) {
				selectedRow.value = plsToSelect
			}
			init = true;
		}

		recentTrips.value = allTrips
	});
}, {immediate: true})

const isAssetVehicleType = computed(() => {
	return isVehicle(selectedAsset.value.type)
})

watchEffect(() => {
	let pathType = 'utilisation';

	if (isAssetVehicleType.value) {
		pathType = 'trip';
	}

	if (selectedRow.value) {
		router.visit(`/map/${selectedAsset.value.hardware_id}/${pathType}/${selectedRow.value.started_at.toUnixInteger()}/${selectedRow.value.finished_at.toUnixInteger()}`,
			{preserveState: true},
		);
	}
})
</script>

<template>
<div v-if="recentTrips?.length > 0" id="asset-trips">
	<DataTable :value="recentTrips" data-key="started_at" v-model:selection="selectedRow" selectionMode="single" scrollable scrollHeight="180px">
		<Column field="trip" :header="selected.obd ? 'Recent Trips' : 'Utilisation'">
			<template #body="{ data }: { data: RecentTrip }">
				<!--Start location - end location name-->
				<span class="flex-grow-1">
					{{ data.started_at.toLocaleString(DateTime.DATETIME_MED) }}
				</span>
				<Tag class="mr-1" v-if="data.finalised !== true">Ongoing</Tag>

			</template>
		</Column>
		<Column field="aaa" :header="'Duration'">
			<template #body="{ data }: { data: RecentTrip }">
				<span class="flex-grow-1" v-tooltip="'until ' + data.finished_at.toLocaleString(DateTime.DATETIME_FULL)">
					{{ toAbsHumanDuration(data.finished_at, data.started_at) }}
				</span>

				<div class="flex justify-content-between flex-wrap">
					<i style="background: #c4b5fd;color:#000;padding:8px;margin-left:4px;" class="block pi border-circle text-center pi-search flex flex-1 justify-content-end align-items-end"></i>
				</div>

			</template>
		</Column>
		<!--
		<Column field="aaa" style="">
			<template #body="{ data }: { data: RecentTrip }">
				<Link preserve-state as="a" :only="['trip']" :href="linkToViewTrip(selectedAsset.hardware_id, data.started_at.toUnixInteger(), data.finished_at.toUnixInteger())" class="mr-5 flex align-items-center mt-3 no-underline text-700 hover:underline">
					<Button icon="pi pi-search"></Button>
				</Link>
			</template>
		</Column>-->
	</DataTable>
</div>
</template>

<style lang="scss">
.p-datatable .p-datatable-tbody > tr > td {
	padding: 0.5rem 0.5rem;
}

.aaa .p-datatable .p-datatable-thead th {
	background: inherit !important;
	padding: 0 0.5rem 0.5rem 0.5rem !important;
}

#asset-trips {
	padding-bottom: 2rem;
}
.p-highlight td {
	background: var(--primary-700) !important;
}
</style>
