<script setup lang="ts">
import { toRef } from 'vue';

const props = defineProps<{
    percentage: number
}>();

const percentage = toRef(props, 'percentage');

const pickColour = (percentage: number) => {
    if (!percentage) {
        return "util-low";
    }

    if (percentage >= 66) {
        return "util-high";
    }

    if (percentage >= 33) {
        return "util-mid";
    }

    return "util-low";
}
</script>
<template>
    <Avatar class="mr-2" size="normal"
            :class="pickColour(percentage)"
            shape="circle" />
</template>
<style lang="scss">
.util-high {
    background-color: rgb(32, 223, 114);
}

.util-mid {
    background-color: rgb(229, 134, 26);
}

.util-low {
    background-color: rgb(181, 74, 88);
}

.p-avatar {
    width: 1.5rem !important;
    height: 1.5rem !important;
}
</style>