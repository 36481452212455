<script setup lang="ts">
import ContainerTreeSelect from "@/components/ContainerTreeSelect.vue";
import { ref, toRef } from "vue";
import { watchDebounced } from "@vueuse/core";
import { useFilterScopeStore } from "@/store/filterscope";
import AssetTypeFilter from "@/components/AssetTypeFilter.vue";

const filterState = useFilterScopeStore();

interface ContainerItemV2 {
	id: string;
	name: string;
	parent_container_id?: string;
	asset_count: number;
}

type NewContainerlist = { [key: string]: ContainerItemV2 }

const props = defineProps<{
	containers: NewContainerlist
}>();

const containerItems = toRef(props, "containers");

const searchBox = ref(filterState.fuzzyMatch);
watchDebounced(
	searchBox,
	async () => {
		filterState.fuzzyMatch = searchBox.value
		//fuzzyFilter.value = searchBox.value;
	},
	{ debounce: 250, maxWait: 500 }
);

const filterUpdate = async (filters: string[]) => {
	filterState.containers = filters
};
</script>

<template>
	<div class="px-3 py-2">
		<!--
		<div class="field-radiobutton mb-0">
		<InputSwitch id="option1" v-model="switchValue" />
		<label for="option1">Branches</label>
		<div class="text-900 text-xl font-semibold">Branches</div>
		</div>
		-->
		<div class="field-checkbox" v-if="false">
			<div class="text-900 text-xl text-center font-semibold">Filter</div>
			<!--<Checkbox name="checkbox-1" v-model="allBranches" binary inputId="checkbox-1"></Checkbox>-->
		</div>

		<ContainerTreeSelect :initialSelect="filterState.containers" @filtered="filterUpdate" :containers="containerItems" />

		<AssetTypeFilter />

		<!--<Button type="button" icon="pi pi-plus" label="Add New" class="p-button-outlined p-button-sm"></Button>-->
	</div>
	<div class="px-3 pb-2">
		<div class="p-inputgroup">
			<span class="p-inputgroup-addon">
				<i class="pi pi-search"></i>
			</span>
			<InputText v-model="searchBox" placeholder="Search" />
		</div>
	</div>
</template>

<style lang="scss">
.cls-1, svg.feet path {
    fill: #dfe1e3 !important;
}

.cls-2, .cls-3 {
    stroke: #dfe1e3 !important;
}
</style>
