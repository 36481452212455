<script lang="ts" setup>
import { ref } from "vue";
import AppLayout from "@/layout/AppLayout.vue";

const menuItems = ref([
    {
        label: 'Management',
        items: [
            {
                label: 'Hardware',
                icon: 'pi pi-fw pi-user-edit',
                to: '/management/trackers',
            },
            {
                label: 'Assets',
                icon: 'pi pi-fw pi-user-edit',
                to: '/management/assets',
            },
            {
                label: 'Sites',
                icon: 'pi pi-fw pi-user-edit',
                to: '/management/sites',
            },
            {
                label: 'Users',
                icon: 'pi pi-fw pi-user-edit',
                to: '/management/users',
            }
        ]
    }
]);
</script>
<template>
  <AppLayout menu-mode="static" :is-padded="false">
    <div class="card">
      <slot />
    </div>
  </AppLayout>
</template>
<style>
#mngtmenu .p-menu {
  width: 100% !important;
}
</style>
