<script setup lang="ts">
import { toRef } from "vue";
import { Link } from "@inertiajs/vue3";
import { DateTime } from "luxon";
import { AssetTypeCar, AssetTypeFuelTruck, AssetTypeGenerator, AssetTypeLightTower } from "@/db";
import type { AssetTableItem } from '@/db';
import CarIcon from '@/components/icons/CarIcon.vue';
import LightTowerIcon from '@/components/icons/LightTowerIcon.vue';
import FuelTruckIcon from '@/components/icons/FuelTruckIcon.vue';
import GeneratorIcon from '@/components/icons/GeneratorIcon.vue';

const props = defineProps<{
	device: AssetTableItem,
	isFocused: boolean,
	site: string
}>();

const device = toRef(props, 'device');
const isFocused = toRef(props, 'isFocused');

// occurring after installation.
const unpoweredState = (d8: string) => {
	const lastUpdated = DateTime.fromISO(d8)

	if (!lastUpdated.isValid) {
		return "Tracker was not receiving power on the last update"
	}

	return `Tracker was not receiving power on the last update\n ${lastUpdated.toLocaleString(DateTime.DATETIME_FULL)}.`
}

const makeLink = () => {
	return `/map/${device.value.hardware_id}`
}
</script>

<template>
    <Link preserve-state :href="makeLink()" as="div"
		:class="{'selected-device': isFocused, 'on-rn': device?.flag_running}"
         class="flex align-items-center border-bottom-1 border-item p-2 pr-3 cursor-pointer transition-colors transition-duration-150">
            <div class="flex-none relative md:mr-3">
                <!--<i v-if="device.type === AssetTypeNone" class="pi pi-globe w-3rem h-3rem" style="font-size: 2rem"></i>-->
                <LightTowerIcon v-if="device.type === AssetTypeLightTower" class="w-3rem h-3rem" style="font-size: 2rem"></LightTowerIcon>
                <FuelTruckIcon v-if="device.type === AssetTypeFuelTruck" class="w-3rem h-3rem" style="font-size: 2rem"></FuelTruckIcon>
                <GeneratorIcon v-if="device.type === AssetTypeGenerator" class="w-3rem h-3rem" style="font-size: 2rem"></GeneratorIcon>
				<CarIcon width="50" height="50" v-if="device.type === AssetTypeCar" />
            </div>

            <div class="flex-grow-1 md:flex">
                <span class="text-900 text-lg font-semibold block">{{ device.name }}</span>
            </div>
            <div v-tooltip="unpoweredState(null)" v-if="device.obd === false && device.flag_powered === false" class="flex mr-1 font-large">
                <i class="pi pi-exclamation-circle mr-2 off-state"></i>
            </div>
			<div v-tooltip="'Low battery voltage'" class="flex font-large" v-if="device.obd === false && device.flag_powered === true && device.flag_low_voltage">
                <i class="pi pi-bolt mr-2 currentign badvolrtage"></i>
            </div>
			<div class="flex flex-column">
				<p class="flex align-items-end justify-content-end text-600 text-overflow-ellipsis overflow-hidden white-space-nowrap text-sm" style="margin:0 !important">{{ site }}</p>
				<div class="flex flex-row justify-content-end">
					<Tag class="flex ml-1" v-if="device.flag_moving" severity="info">Moving</Tag>
				</div>
			</div>

            <!--<div class="flex font-large">
                <i class="pi pi-bolt mr-2 currentign ignitioned"></i>
            </div>-->
    </Link>
</template>

<style>
.list-group-item {
    &:hover {
        cursor: pointer;
        opacity: 85%;
    }
}

.selected-device {
    @apply hover:--primary-700;

    background-color: var(--primary-700);
    &:hover {
        background-color: var(--primary-700);
    }
}

.on-rn {
	border-left-color: var(--green-400) !important;
	border-left-width: 10px !important;
	border-left-style: solid !important;
}

.ignitioned {
    color: #82FF49;
}
.border-item {
	border-color: var(--surface-border);
}

.currentign {
    font-size: 1.5rem;
}
.badvolrtage {
    color: #ffaf00;
}

.off-state {
    font-size: 1.5rem;
    color: #F00;
}
</style>
