<script setup lang="ts">
import { ref } from "vue";
import AppSubMenu from "./AppSubMenu.vue";
import { useLayout } from '@/layout/composables/layout';

const { layoutConfig } = useLayout();

const model = ref([
    //{
        //label: "Navigation",
        //items: [
            {
                label: "Map",
                icon: "pi pi-fw pi-map-marker",
                to: "/map",
				root: true,
            },
			{
				label: "Assets",
				icon: "pi pi-fw pi-car",
				to: "/assets",
			},
			{
				label: "Servicing",
				icon: "pi pi-fw pi-calendar-times",
				to: "/servicing/overview",
			},
    /*
    {
      label: "Trackers",
      icon: "pi pi-th-large",
      items: [
        {
          label: "Location",
          icon: "pi pi-fw pi-compass",
          items: [
            {
              label: "List",
              icon: "pi pi-fw pi-book",
              to: "/"
            },
            {
              label: "Vehicles",
              icon: "pi pi-fw pi-car",
              to: "/"
            },
            {
              label: "Generators",
              icon: "pi pi-fw pi-bolt",
              to: "/"
            },
            {
              label: "Towers",
              icon: "pi pi-fw pi-building",
              to: "/"
            }
          ]
        },
        {
          label: "Reporting",
          icon: "pi pi-chart-line pi-calendar",
          to: "/report"
        }
      ]
    },
     */
            {
                label: "Reports",
                icon: "pi pi-fw pi-chart-pie",
                to: "/reports"
            },
			{
				label: "Setup",
				icon: "pi pi-fw pi-globe",
				items: [
		            {
						label: "Sites",
						icon: "pi pi-fw pi-sitemap",
						to: "/management/sites"
					},
					{
						label: "Users",
						icon: "pi pi-fw pi-users",
						to: "/management/users"
					},
					{
						label: "Hardware",
						icon: "pi pi-fw pi-globe",
						to: "/management/trackers"
					},
					/*{
						label: "Connector",
						icon: "pi pi-fw pi-globe",
						to: "/management/trackers"
					},*/
				]
			},
]);
</script>

<template>
    <AppSubMenu :model="layoutConfig.menuMode.value == 'horizontal' ? model : [{label:'Navigation', items: model}]" />
</template>

<style lang="scss" scoped></style>
