import { createApp, h } from 'vue';
import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import BlockUI from 'primevue/blockui';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Chart from 'primevue/chart';
import CascadeSelect from 'primevue/cascadeselect';
import Carousel from 'primevue/carousel';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import DeferredContent from 'primevue/deferredcontent';
import Dialog from 'primevue/dialog';
import DialogService from 'primevue/dialogservice';
import Divider from 'primevue/divider';
import Dock from 'primevue/dock';
import Dropdown from 'primevue/dropdown';
import DynamicDialog from 'primevue/dynamicdialog';
import Editor from 'primevue/editor';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import Galleria from 'primevue/galleria';
import Image from 'primevue/image';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import Knob from 'primevue/knob';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import Row from 'primevue/row';
import SelectButton from 'primevue/selectbutton';
import ScrollPanel from 'primevue/scrollpanel';
import ScrollTop from 'primevue/scrolltop';
import Skeleton from 'primevue/skeleton';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import SpeedDial from 'primevue/speeddial';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Steps from 'primevue/steps';
import StyleClass from 'primevue/styleclass';
import TabMenu from 'primevue/tabmenu';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tag from 'primevue/tag';
import Terminal from 'primevue/terminal';
import Timeline from 'primevue/timeline';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import VirtualScroller from 'primevue/virtualscroller';
import CodeHighlight from '@/components/CodeHighlight.vue';
import BlockViewer from '@/components/BlockViewer.vue';
import { createPinia } from 'pinia';
import '@/assets/styles.scss';
import { db, DB_KEY } from '@/db';
import { OryPlugin } from '@/plugins/ory';
import { SyncPlugin } from '@/plugins/sync';
import { PositionTracker } from '@/plugins/positions';
import { MapRegistry } from '@/plugins/mapregistry';
import { createInertiaApp } from '@inertiajs/vue3';
import RouterLink from "@/components/RouterLink.vue";
import { Config } from '@/utils/build'
import CodeBlock from 'vue3-code-block';
import Dexie from 'dexie';
import { createI18n } from "vue-i18n";

const pinia = createPinia();

createInertiaApp({
    resolve: name => {
        const pages = import.meta.glob('./pages/**/*.vue', {eager: true});
        return pages[`./pages/${name}.vue`] as any;
    },
    setup({ el, App, props, plugin }) {
		console.log("PROPS",props)
        fetch('/bootstrap').then((res) => {
            return res.json()
        }).then((res) => {
            const vueApp = createApp({ render: () => h(App, props) });

            vueApp
                .use(plugin)
                .use(pinia)

            vueApp.provide(Config, {
                CompanyName: res.CompanyName,
                DebugMode: res.DebugMode,
                OryBase: res.OryBase,
				LogoURL: res.LogoURL,
				PlatformProvider: res.PlatformProvider,
            });

			vueApp.provide(DB_KEY, db);
            vueApp.use(MapRegistry);
            vueApp.use(OryPlugin);
			vueApp.use(SyncPlugin);
            vueApp.use(PrimeVue, { ripple: true });
            vueApp.use(ToastService);
            vueApp.use(DialogService);
            vueApp.use(ConfirmationService);
			vueApp.use(PositionTracker);

            vueApp.directive('tooltip', Tooltip);
            vueApp.directive('badge', BadgeDirective);
            vueApp.directive('ripple', Ripple);
            vueApp.directive('styleclass', StyleClass);
            vueApp.component('RouterLink', RouterLink);

            vueApp.component('CodeBlock', CodeBlock)
            vueApp.component('CodeHighlight', CodeHighlight);
            vueApp.component('BlockViewer', BlockViewer);

            vueApp.component('Accordion', Accordion);
            vueApp.component('AccordionTab', AccordionTab);
            vueApp.component('AutoComplete', AutoComplete);
            vueApp.component('Avatar', Avatar);
            vueApp.component('AvatarGroup', AvatarGroup);
            vueApp.component('Badge', Badge);
            vueApp.component('BlockUI', BlockUI);
            vueApp.component('Breadcrumb', Breadcrumb);
            vueApp.component('Button', Button);
            vueApp.component('Calendar', Calendar);
            vueApp.component('Card', Card);
            vueApp.component('Chart', Chart);
            vueApp.component('Carousel', Carousel);
            vueApp.component('CascadeSelect', CascadeSelect);
            vueApp.component('Checkbox', Checkbox);
            vueApp.component('Chip', Chip);
            vueApp.component('Chips', Chips);
            vueApp.component('ColorPicker', ColorPicker);
            vueApp.component('Column', Column);
            vueApp.component('ColumnGroup', ColumnGroup);
            vueApp.component('ConfirmDialog', ConfirmDialog);
            vueApp.component('ConfirmPopup', ConfirmPopup);
            vueApp.component('ContextMenu', ContextMenu);
            vueApp.component('DataTable', DataTable);
            vueApp.component('DataView', DataView);
            vueApp.component('DataViewLayoutOptions', DataViewLayoutOptions);
            vueApp.component('DeferredContent', DeferredContent);
            vueApp.component('Dialog', Dialog);
            vueApp.component('Divider', Divider);
            vueApp.component('Dock', Dock);
            vueApp.component('Dropdown', Dropdown);
            vueApp.component('DynamicDialog', DynamicDialog);
            vueApp.component('Editor', Editor);
            vueApp.component('Fieldset', Fieldset);
            vueApp.component('FileUpload', FileUpload);
            vueApp.component('Galleria', Galleria);
            vueApp.component('Image', Image);
            vueApp.component('InlineMessage', InlineMessage);
            vueApp.component('Inplace', Inplace);
            vueApp.component('InputMask', InputMask);
            vueApp.component('InputNumber', InputNumber);
            vueApp.component('InputSwitch', InputSwitch);
            vueApp.component('InputText', InputText);
            vueApp.component('Knob', Knob);
            vueApp.component('Listbox', Listbox);
            vueApp.component('MegaMenu', MegaMenu);
            vueApp.component('Menu', Menu);
            vueApp.component('Menubar', Menubar);
            vueApp.component('Message', Message);
            vueApp.component('MultiSelect', MultiSelect);
            vueApp.component('OrderList', OrderList);
            vueApp.component('OrganizationChart', OrganizationChart);
            vueApp.component('OverlayPanel', OverlayPanel);
            vueApp.component('Paginator', Paginator);
            vueApp.component('Panel', Panel);
            vueApp.component('PanelMenu', PanelMenu);
            vueApp.component('Password', Password);
            vueApp.component('PickList', PickList);
            vueApp.component('ProgressBar', ProgressBar);
            vueApp.component('ProgressSpinner', ProgressSpinner);
            vueApp.component('RadioButton', RadioButton);
            vueApp.component('Rating', Rating);
            vueApp.component('Row', Row);
            vueApp.component('SelectButton', SelectButton);
            vueApp.component('ScrollPanel', ScrollPanel);
            vueApp.component('ScrollTop', ScrollTop);
            vueApp.component('Slider', Slider);
            vueApp.component('Sidebar', Sidebar);
            vueApp.component('Skeleton', Skeleton);
            vueApp.component('SpeedDial', SpeedDial);
            vueApp.component('SplitButton', SplitButton);
            vueApp.component('Splitter', Splitter);
            vueApp.component('SplitterPanel', SplitterPanel);
            vueApp.component('Steps', Steps);
            vueApp.component('TabMenu', TabMenu);
            vueApp.component('TabView', TabView);
            vueApp.component('TabPanel', TabPanel);
            vueApp.component('Tag', Tag);
            vueApp.component('Textarea', Textarea);
            vueApp.component('Terminal', Terminal);
            vueApp.component('TieredMenu', TieredMenu);
            vueApp.component('Timeline', Timeline);
            vueApp.component('Toast', Toast);
            vueApp.component('Toolbar', Toolbar);
            vueApp.component('ToggleButton', ToggleButton);
            vueApp.component('Tree', Tree);
            vueApp.component('TreeSelect', TreeSelect);
            vueApp.component('TreeTable', TreeTable);
            vueApp.component('TriStateCheckbox', TriStateCheckbox);
            vueApp.component('VirtualScroller', VirtualScroller);


			const i18n = createI18n({
				numberFormats: {
					'en-US': {
						currency: {	style: 'currency', currency: 'USD', notation: 'standard'},
						decimal: { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2},
						percent: { style: 'percent', useGrouping: false	},
						time: { style: 'unit', unit: 'hour', unitDisplay: "short" },
					},
				}
			})

			vueApp.use(i18n);

			(async() => {
				// Dexie.VersionError may occur with deployment rollbacks, or development.
				// Delete IndexedDB and refresh page to re-initialize and resolve the problem.
				db.open().catch(Dexie.VersionError, (e) => {
					db.delete().then(() => {
						location.reload();
					})
				})
			})();

            vueApp.mount(el);
        })
    }
});

