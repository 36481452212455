<script setup lang="ts">
import { ref } from "vue";
import AppConfig from "@/layout/AppConfig.vue";
import { Link, router } from "@inertiajs/vue3";
import { useToast } from "primevue/usetoast";

const props = defineProps<{
    is_successful: boolean
    error_message?: string
    email?: string
}>();

const password = ref("");
const confirmedPassword = ref("");

const toast = useToast();
const formLoading = ref(false);
const pwErr = ref("")

const submitCode = async (e: Event) => {
    e.preventDefault();

    if (password.value != confirmedPassword.value) {
        pwErr.value = "Passwords do not match";
        return
    }

    formLoading.value = true;

    const rawResponse = await fetch("", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "password": password.value,
            "confirmed_password": confirmedPassword.value
        })
    });

    formLoading.value = false;
    if (rawResponse.ok) {
        router.visit("/login?pwupdated=1");
    } else {
        toast.add({ severity: "error", detail: "Registration could not be submitted" });
    }
};
</script>

<template>
    <Toast />

    <div class="px-5 min-h-screen flex justify-content-center align-items-center">
        <div class="border-1 w-40rem surface-border surface-card border-round py-7 px-4 md:px-7 z-1">
            <div class="py-3">
                <Link
                    class="text-600 cursor-pointer hover:text-primary cursor-pointer ml-auto transition-colors transition-duration-300"
                    href="/">&lt; Home
                </Link>
            </div>

            <p v-if="props.is_successful">You have been invited to create an account.</p>

            <Message :closable="false" v-if="!props.is_successful && props.error_message" severity="error">{{ props.error_message }}</Message>
            <Message v-if="pwErr" severity="error">{{ pwErr }}</Message>

            <div class="flex flex-column">
                <form @submit="submitCode">
                    <div class="mb-4">
                        <div class="text-900 text-xl font-bold mb-2">Enter a password</div>
                        <span class="text-600 font-medium"></span>
                    </div>

                    <span class="p-input-icon-left w-full mb-4">
                      {{ props.email }}
                    </span>

                    <span class="p-input-icon-left w-full mb-4">
                        <InputText :disabled="!props.is_successful" id="password" v-model="password" :class="{'p-invalid': false}" type="password"
                                   class="w-full md:w-25rem" placeholder="New Password" />
                    </span>
                    <span class="p-input-icon-left w-full mb-4">
                        <InputText :disabled="!props.is_successful" id="confirm_password" v-model="confirmedPassword" :class="{'p-invalid': false}" type="password"
                                   class="w-full md:w-25rem" placeholder="Confirm Password" />
                    </span>

                    <Button :disabled="!props.is_successful" label="Create account" :loading="formLoading" class="w-full mb-4" type="submit"></Button>
                </form>
            </div>
        </div>
    </div>
    <AppConfig simple />
</template>
