<script setup lang="ts">
import { ref, toRaw, toRef } from "vue";
import Wrapper from "@/pages/management/Wrapper.vue";
import ContainerTreeSelect from "@/components/ContainerTreeSelect.vue";

interface ContainerItemV2 {
    id: string;
    name: string;
    asset_count: number;
    parent_container_id: string;
}

type NewContainerlist = { [key: string]: ContainerItemV2 }

const formLoading = ref(false);

const props = defineProps<{
    containers: NewContainerlist,
}>();
const containerItems = toRef(props, "containers");
const errorMessage = ref("");
const userCreated = ref("");

interface ErrorResponse {
    message?: string
}

const form = ref<{
    first: string,
    last: string
    email: string
    role: { name: string, value: string }
    site: { [key: string]: string }
}>({ first: "", last: "", email: "", role: { name: "User", value: "user" }, site: {} });

const resetForm = () => {
    form.value = { first: "", last: "", email: "", role: { name: "User", value: "user" }, site: {} };
}

const options = ref([
    { name: "Administrator", value: "administrator" },
    { name: "Operator", value: "operator" },
    { name: "User", value: "user" },
    { name: "Guest", value: "guest" }
]);

const inviteUser = async (e: Event) => {
    e.preventDefault();

    formLoading.value = true;
    errorMessage.value = "";

    const rawResponse = await fetch("", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "email": form.value.email,
            "role": form.value.role.value,
            "site": Object.keys(form.value.site)?.[0] || null,
            "firstName": form.value.first,
            "lastName": form.value.last
        })
    });

    formLoading.value = false;

    if (rawResponse.ok) {
        userCreated.value = `An email has be sent to ${ toRaw(form.value.first) } (${ toRaw(form.value.email) }) with further instructions.`
        resetForm();
    } else {
        const got = await rawResponse.json() as ErrorResponse

        if (got?.message) {
            errorMessage.value = got.message
        } else {
            errorMessage.value = "failed to send user invitation"
        }
    }
};
</script>

<template>
    <Wrapper>
        <h3 class="mb-4 block">Invite User</h3>
        <form @submit="inviteUser">
            <Message :closable="false" v-if="errorMessage" severity="error">{{ errorMessage }}</Message>
            <Message :closable="true" v-if="userCreated" severity="info">{{ userCreated }}</Message>

            <div class="grid">
                <div class="col-12 lg:col-2">
                    <div class="text-900 font-medium text-xl mb-3">Profile</div>
                    <p class="m-0 p-0 text-600 line-height-3 mr-3">Invited user will receive an email with further
                        instructions.</p>
                </div>
                <div class="col-12 lg:col-10">
                    <div class="grid formgrid p-fluid">

                        <div class="field mb-4 col-12">
                            <label htmlFor="role" class="font-medium text-900"> Role </label>

                            <SelectButton id="role" v-model="form.role" :options="options" optionLabel="name"
                                          aria-labelledby="multiple" />
                        </div>
                        <div class="field mb-4 col-12 md:col-6">
                            <label htmlFor="first" class="font-medium text-900"> First Name </label>
                            <InputText id="first" required v-model="form.first" type="text" placeholder="e.g. John" />
                        </div>
                        <div class="field mb-4 col-12 md:col-6">
                            <label htmlFor="last" class="font-medium text-900"> Last </label>
                            <InputText id="last" required v-model="form.last" type="text" placeholder="Citizen" />
                        </div>
                        <div class="field mb-4 col-12 md:col-6">
                            <label htmlFor="email" class="font-medium text-900"> Email </label>
                            <InputText id="email" required v-model="form.email" type="email" placeholder="person@example.com" />
                        </div>
                        <div class="field mb-4 col-12 md:col-6">
                            <label htmlFor="site" class="font-medium text-900"> Site <small class="pl-6" style="font-weight: 100;" v-if="form.role.value == 'administrator'"><i>Administrators have access to all sites.</i></small></label>

                            <ContainerTreeSelect :disabled="form.role.value == 'administrator'" v-model="form.site" :single="true" placeholder="None selected"
                                                 :containers="containerItems" />
                        </div>
                        <div class="col-12">
                            <Button type="submit" :loading="formLoading" label="Send Invitation" class="w-auto mt-3"></Button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </Wrapper>
</template>

<style scoped lang="scss">
</style>
