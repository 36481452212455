<script lang="ts" setup>
import {inject, onBeforeUnmount, onMounted, onUnmounted} from 'vue';
import { mapSymbol } from '@/types';
import { useGeoJSONStore } from '@/store/geojson';
import {router} from "@inertiajs/vue3";

const map = inject(mapSymbol)!;

const geoJSONStore = useGeoJSONStore();

onUnmounted(() => {
	if (!map.value) {
		return
	}
	// Causes error during navigation.
	map.value.removeLayer('moving')
	map.value.removeImage('yotea-follow')
});

onMounted(async () => {
	await geoJSONStore.waitForReady();

	const image = await map.value.loadImage('/images/markers/marker-follow-smol.png');
	map.value.addImage('yotea-follow', image.data, {sdf: false});

	map.value.addLayer({
		id: 'moving',
		type: 'symbol',
		source: 'all',
		'paint': {
			'text-color': '#202',
			'text-halo-color': '#fff',
			'text-halo-width': 1,
			"icon-color": "#1a7a08",
			"icon-halo-color": "#e4be8b",
			"icon-halo-width": 4
		},
		layout: {
			'icon-image': 'yotea-follow',
			// required as cooperative for clustering layers
			'icon-overlap': 'always',
			'icon-rotate': ['get', 'rotation'],
			'icon-allow-overlap': true,
			'text-allow-overlap': false,
			'icon-anchor': 'center',
			'icon-size': 0.5,
			// get the year from the source's "year" property
			'text-field': ['get', 'name'],
			'text-optional': true,
			'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
			'text-size': 13,
			'text-offset': [0, 1.5],
			'text-anchor': 'top'
		},
		filter: ['all', ['>', ['get', 'speed'], 22], ['!', ['has', 'point_count']]],
	});

	map.value.on('click', 'moving', async(e) => {
		const features = map.value.queryRenderedFeatures(e.point, {
			layers: ['moving']
		});
		const hello = features[0];

		router.get(`/map/${encodeURIComponent(hello.properties['serial'])}`, { }, { preserveState: true })
	})
});
</script>

<template>
</template>
