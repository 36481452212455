import { defineStore } from "pinia";
import { Configuration, FrontendApi } from "@ory/client";
import { Config } from "@/utils/build"
import { inject } from "vue";


const OryCfg = new Configuration({
    baseOptions: {
        withCredentials: true,
    }
});

const Ory = new FrontendApi(OryCfg)

export type User = {
    name: string,
    avatar: string,
}

export const useAuthStore = defineStore("auth", {
    state: () => ({
        userData: null,
        logoutUrl: null,
        checked: false,
    }),

    actions: {
      async session() {
          if (!OryCfg.basePath) {
              const cfg = inject(Config);
              OryCfg.basePath = cfg.OryBase
          }

          await Ory.toSession()
              .then(({ data }) => {
                  this.userData = data
              })
              .catch(async () => {
                  console.log('[Ory] User has no session.');
              });


          await Ory.createBrowserLogoutFlow()
              .then(({ data }) => {
                  this.logoutUrl = data.logout_url
                  //this.logout = data
              })
              .catch(async () => {
                  console.log('[Ory] User has no session.');
              });
      },
      async login(id, pw, method = 'password') {
          if (!OryCfg.basePath) {
              const cfg = inject(Config);
              OryCfg.basePath = cfg.OryBase
          }

          const eggs = await Ory.createBrowserLoginFlow({
            //returnTo: oryBasePath
        })

        const rawResponse = await fetch(eggs.data.ui.action, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            // @ts-ignore
            csrf_token: eggs.data.ui.nodes[0].attributes.value,
            identifier: id,
            password: pw,
            method: method
          })
        });

        const content = await rawResponse.json();
          return {
              content,
              ok: rawResponse.ok
          }
      }
    },
  });

