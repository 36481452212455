import type { InjectionKey } from "vue";

export const version: string = import.meta.env.VITE_VERSION || "development";

export interface BootstrapConfig {
    CompanyName: string
    DebugMode: boolean
    OryBase: string
	LogoURL: string
	PlatformProvider: string
}

export const Config = Symbol() as InjectionKey<BootstrapConfig>
