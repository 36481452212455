<script setup lang="ts">
import Wrapper from "@/pages/management/Wrapper.vue";
import { ref, watch } from "vue";
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import Chip from "primevue/chip";
import { Link } from "@inertiajs/vue3"
import { useUrlSearchParams } from '@vueuse/core'

const props = defineProps<{
    trackers: [],
    role: string
}>()

const params = useUrlSearchParams('history')


const filters = ref();
const initFilters = () => {
    filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        trackthis_serial: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        imei: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        assigned: { value: null, matchMode: FilterMatchMode.IN },
    };
};
const representatives = ref([
    { name: 'Assigned' },
    { name: 'Unassigned' },
]);

initFilters();

// ?keyword=TTKMK... will apply global search filter
watch(() => params.keyword,
      (qs) => {
          if (!qs?.length) {
              return
          }

          filters.value.global.value = qs
      }, {immediate: true})

const clearFilter = () => {
    initFilters();
};

const columns = [
    //{field: 'id', header: 'ID'},
    {field: 'trackthis_serial', header: 'Serial'},
   // {field: 'assigned', header: 'Assigned'},
];

const linkToAssetList = (ttSerial: string) => {
    return `/assets?keyword=${ttSerial}`
}
</script>
<template>
  <Wrapper>
    <h1>Hardware</h1>
      <div v-if="role == 'user'">
          You do not have permission to see this page.
      </div>

    <DataTable v-if="role != 'user'" v-model:filters="filters" filterDisplay="menu" data-key="id" sortField="trackthis_serial" :sortOrder="1"
               :globalFilterFields="['trackthis_serial', 'imei']"
               :value="props.trackers" paginator :rows="10" :rowsPerPageOptions="[10, 20, 50]" tableStyle="width: 100%">
        <template #header>
            <div class="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined @click="clearFilter()" />
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                </span>
            </div>
        </template>
        <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header"></Column>
        <Column header="IMEI">
            <template #body="{ data, index }">
                <span style="font-family: monospace;">{{ data.imei }}</span>
            </template>
        </Column>
        <Column style="flex: 0 0 4rem" header="Assigned To" filterField="assigned" :showFilterMatchModes="false" sortable field="asset_id">
            <template #body="{ data, index }">
                <Link :href="linkToAssetList(data.trackthis_serial)">
                    <Chip icon="pi pi-external-link" v-if="data.asset_id" :label="data.asset_name" />
                </Link>
            </template>
            <template #filter="{ filterModel }">
                <Dropdown v-model="filterModel.value" :options="representatives" optionLabel="name" placeholder="Any" class="p-column-filter">
                    <template #option="slotProps">
                        <div class="flex align-items-center gap-2">
                            <span>{{ slotProps.option.name }}</span>
                        </div>
                    </template>
                </Dropdown>
            </template>
        </Column>
        <Column style="flex: 0 0 4rem" header="Actions">
            <template #body="{ data, index }">
            </template>
        </Column>
    </DataTable>
  </Wrapper>
</template>
