<script setup lang="ts">
import { useLayout } from '@/layout/composables/layout';
import { inject, ref } from "vue";
import { useAuthStore } from "@/store/auth";
import { version, Config } from "@/utils/build"
import { db } from "@/db";
import { router } from '@inertiajs/vue3'

const configuration = inject(Config);
const authStore = useAuthStore();

(async() => {
  if (!authStore.userData) {
    await authStore.session()
  }
})()

const { layoutState } = useLayout();
const display = ref(false);

const open = () => {
    display.value = true;
};
const close = () => {
    display.value = false;
};

const signOut = async (e: Event) => {
    e.preventDefault();

	await db.coordinator.clear()
	await db.assets.clear()
	await db.pointers.clear()

    window.location = authStore.logoutUrl;
}

</script>

<template>
    <Sidebar v-model:visible="layoutState.profileSidebarVisible.value" position="right" class="layout-profile-sidebar w-full sm:w-25rem">
        <div class="flex flex-column mx-auto md:mx-0">
            <span class="mb-2 font-semibold">Welcome</span>
            <span class="text-color-secondary font-medium mb-5">{{ authStore?.userData?.identity?.traits?.name?.first }} {{ authStore?.userData?.identity?.traits?.name?.last }}</span>

            <ul class="list-none m-0 p-0">
              <!--
                <li>
                    <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                        <span>
                            <i class="pi pi-money-bill text-xl text-primary"></i>
                        </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">Billing</span>
                            <p class="text-color-secondary m-0"></p>
                        </div>
                    </a>
                </li>
                <li>
                    <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                        <span>
                            <i class="pi pi-cog text-xl text-primary"></i>
                        </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">Settings</span>
                            <p class="text-color-secondary m-0"></p>
                        </div>
                    </a>
                </li>
                -->
                <li>
                    <a @click="signOut" :href="authStore.logoutUrl" class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                        <span>
                            <i class="pi pi-power-off text-xl text-primary"></i>
                        </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">Sign Out</span>
                            <p class="text-color-secondary m-0"></p>
                        </div>
                        <!--
                            <ProgressSpinner style="width: 20px; height: 20px; float:right; margin: 0 0 0 auto;" stroke-width="5" />
                        -->
                    </a>
                </li>

                <span class="text-xs opacity-40 block text-right">{{ version }}</span>

                <Dialog header="Dialog" v-model:visible="display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
                    <p class="line-height-3 m-0"><pre>{{ JSON.stringify(authStore.userData, null, "\t")}}</pre></p>
                    <template #footer>
                        <Button label="Ok" @click="close" icon="pi pi-check" class="p-button-outlined" />
                    </template>
                </Dialog>

                <div v-if="configuration.DebugMode">
                    <Button label="Debug: User Session" icon="pi pi-external-link" style="width: auto" @click="open" />
                </div>
            </ul>
        </div>
    </Sidebar>
</template>

<style>
.p-sidebar a {
    color: rgba(255, 255, 255, 0.87);
}
</style>
