<script setup>
import { ref, watch } from 'vue';

const route = {
  meta: {breadcrumb:{}},
  fullPath:'',
};

const breadcrumbRoutes = ref([]);

const setBreadcrumbRoutes = () => {
    //if (route.meta.breadcrumb) {
       // breadcrumbRoutes.value = route.meta.breadcrumb;

     //   return;
   // }

    //breadcrumbRoutes.value = route.fullPath
    //    .split('/')
    //    .filter((item) => item !== '')
    //    .filter((item) => isNaN(Number(item)))
   //     .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
};

setBreadcrumbRoutes();
/*watch(
    route,
    () => {
        setBreadcrumbRoutes();
    },
    { immediate: true }
);*/
</script>

<template>
    <nav class="layout-breadcrumb">
        <ol>
            <template v-for="(breadcrumbRoute, i) in breadcrumbRoutes" :key="breadcrumbRoute">
                <li>{{ breadcrumbRoute }}</li>
                <li v-if="i !== breadcrumbRoutes.length - 1" class="layout-breadcrumb-chevron">/</li>
            </template>
        </ol>
    </nav>
</template>
