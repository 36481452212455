<script setup lang="ts">
import {
	getCurrentInstance,
	inject,
	markRaw,
	onUnmounted,
	onMounted,
	provide,
	ref,
	shallowRef, toRef,
	watch, nextTick
} from "vue";
import { Map as MapboxMap, Popup } from 'maplibre-gl';
import type { MapController } from '@/types';
import { $MapInstance, isLoadedSymbol, $Popup, mapSymbol, isInitializedSymbol } from '@/types';
import type { BBox2d } from "@turf/helpers/dist/js/lib/geojson";
import { injectionKey } from "@/plugins/mapregistry";
import { until, watchOnce } from "@vueuse/core";

const mapRegistry = inject(injectionKey);
const ready = ref(false);
const mapContainer = shallowRef<HTMLDivElement | null>(null);
const maplibre = shallowRef<MapboxMap>();

const props = defineProps<{
    classes?: string,
    bounds?: { boundary: BBox2d },
    tilingKey: string,
}>();

const bounds = toRef(props, 'bounds');
const isLoaded = ref(false);
const isInitialized  = ref(false);
const popup = new Popup({offset: 40, maxWidth: 'none'})

provide(isLoadedSymbol, isLoaded);
provide($Popup, popup)
provide(mapSymbol, maplibre);
provide(isInitializedSymbol, isInitialized);

watch(bounds, async() => {
	await until(isLoaded).toBe(true);

	let shouldApply = bounds.value.boundary.filter((r) => r > 0).length > 0
	while (!shouldApply || bounds.value.boundary == [0, 0, 0, 0]) {
		await new Promise(resolve => setTimeout(resolve, 1000));

		shouldApply = bounds.value.boundary.filter((r) => r > 0).length > 0
	}

	maplibre.value?.fitBounds(bounds.value.boundary, {
		maxDuration: 1,
		maxZoom: 14,
	});
}, {immediate: true})

onUnmounted(() => {
    maplibre.value?.remove();
});

// @ts-ignore
const theMapInstance = mapRegistry.register(markRaw(getCurrentInstance()));

provide($MapInstance, {
	addMarker: (marker) => {
		marker.addTo(maplibre.value);
	},
} as MapController);

onMounted(() => {
	theMapInstance.isMounted = true;
	isInitialized.value = true;

	maplibre.value = markRaw(new MapboxMap({
		container: mapContainer.value,
		style: `https://api.maptiler.com/maps/streets/style.json?key=${props.tilingKey}`,
		center: [0, 0],
	}));
	maplibre.value.setPadding({top: 0, bottom:0, right: 0, left: 500})
	// @ts-ignore
	theMapInstance.map = maplibre;

    maplibre.value.on("load", (ev) => {
        maplibre.value.resize();
		nextTick(() => {
			isLoaded.value = true
			theMapInstance.isLoaded = true;
		})
    });

    maplibre.value.getCanvas().addEventListener("webglcontextlost", () => {
        if (maplibre.value) {
            // Tear down
            // Re-initialize
        }
    });
});
</script>

<template>
    <div id="map" class="w-full" :class="classes" ref="mapContainer"></div>
	<slot v-if="isLoaded" />
</template>

<style>
@import "maplibre-gl/dist/maplibre-gl.css";

.mgl-container {
	position: relative;
}
#map {
    width: 100%;
	height: 100%;
}
</style>
