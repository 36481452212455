<script>
export default {
  name: 'Meat',
  props: {
  },
  data: () => {
    return {
      connection: null,
      messages: [],
    };
  },
  created: function() {
    console.log("Starting connection to WebSocket Server")
    this.connection = new WebSocket('ws://localhost:8090/subscribe')

    let app = this;

    this.connection.onmessage = function(event) {
      if (typeof event.data !== "string") {
        console.error("unexpected message type", typeof event.data)
        return
      }
      app.messages.push(event.data)
    }

    this.connection.onopen = function(event) {
      console.log(event)
      console.log("Successfully connected to the echo websocket server...")
    }
  },
  methods: {
    sendMessage: function() {
      console.log(this.connection);
      this.connection.send("hello!");
    }
  },
};
</script>

<template>
  <div id="message-log">
    <p
        v-for="(message, i) in messages"
        :key="i">
      {{ message }}
    </p>

  </div>
  <div id="publish-form-container">
    <form @submit.prevent="sendMessage">
      <input name="message" id="message-input" type="text" />
      <input value="Submit" type="submit" />
    </form>
  </div>
</template>