<script lang="ts" setup>
import {inject, onMounted, onUnmounted, ref} from "vue";
import type { GeoJSONSource } from "maplibre-gl";
import { mapSymbol } from "@/types";
import {useGeoJSONStore} from "@/store/geojson";
import {geoJsons, PositionEmitter} from "@/plugins/positions";
import {Fea} from "@/utils/geojson-db";
import { router } from "@inertiajs/vue3";
import {type AssetTableItem, db} from "@/db";

const map                 = inject(mapSymbol)!;
// IMEI
const mounted = ref(false);

const posEmitter = inject(PositionEmitter)

const geoJSONStore = useGeoJSONStore();

/*
el.className = 'marker';
el.style.backgroundImage = `url(${MarkerLT})`;
el.style.backgroundSize = 'cover';
el.style.width = `31px`;
el.style.height = `42px`;
*/

let handler = null;

onUnmounted(() => {
	if (!map.value) {
		return
	}
	map.value.removeLayer('assets')
	map.value.removeLayer('clusters')
	map.value.removeLayer('cluster-count');
	map.value.removeSource('all')
	map.value.removeImage('TYPE_LIGHT_TOWER');
	map.value.removeImage('TYPE_GENERATOR');
	map.value.removeImage('TYPE_FUEL_TRUCK');
	map.value.removeImage('TYPE_OTHER');
	map.value.removeImage('TYPE_CAR');
	map.value.removeImage('TYPE_NONE');

	if (handler !== null) {
		posEmitter.off('*', handler)
	}
});

const start = async() => {
	const theMap = new Map<string, AssetTableItem>();
	for (const asset of await db.assets.toArray()) {
		theMap.set(asset.hardware_id, asset)
	}

	return geoJSONStore.format(theMap);
}

onMounted(async () => {
	await geoJSONStore.waitForReady();

	const imageLT = await map.value.loadImage('/images/markers/marker-lt.png');
	const imageG = await map.value.loadImage('/images/markers/marker-generator.png');
	const imageT = await map.value.loadImage('/images/markers/marker-truck.png');
	const imageGeneric = await map.value.loadImage('/images/markers/marker.png');

	map.value.addImage('TYPE_LIGHT_TOWER', imageLT.data);
	map.value.addImage('TYPE_GENERATOR', imageG.data);
	map.value.addImage('TYPE_FUEL_TRUCK', imageT.data);

	map.value.addImage('TYPE_OTHER', imageGeneric.data);
	map.value.addImage('TYPE_CAR', imageGeneric.data);
	map.value.addImage('TYPE_NONE', imageGeneric.data);

	const posDB = new Fea();

	posDB.init((await start()).features)
	geoJsons.features = posDB.list()

	map.value.addSource('all', {
		type: 'geojson',
		data: geoJsons,
		cluster: true,
		clusterMaxZoom: 13,
		clusterRadius: 30,
	})

	map.value.addLayer({
		'id': 'assets',
		'type': 'symbol',
		'source': 'all',
		'layout': {
			'icon-image': ['get', 'type'],
			// required as cooperative for clustering layers
			'icon-overlap': 'always',
			'icon-allow-overlap': true,
			'text-allow-overlap': false,
			'icon-anchor': 'bottom',
			'icon-size': 0.8,
			// get the year from the source's "year" property
			'text-field': ['get', 'name'],
			'text-optional': true,
			'text-font': [
				'Open Sans Semibold',
				'Arial Unicode MS Bold'
			],
			'text-size': 13,
			'text-offset': [0, 0],
			'text-anchor': 'top'
		},
		filter: ['all', ['<=', 'speed', 22], ['!has', 'point_count']],
	});

	map.value.addLayer({
		id: 'clusters',
		type: 'circle',
		source: 'all',
		filter: ['all', ['has', 'point_count']],
		paint: {
			// Use step expressions (https://maplibre.org/maplibre-style-spec/#expressions-step)
			// with three steps to implement three types of circles:
			//   * Blue, 20px circles when point count is less than 100
			//   * Yellow, 30px circles when point count is between 100 and 750
			//   * Pink, 40px circles when point count is greater than or equal to 750
			'circle-color': [
				'step',
				['get', 'point_count'],
				'#51bbd6',
				100,
				'#f1f075',
				750,
				'#f28cb1'
			],
			'circle-radius': [
				'step',
				['get', 'point_count'],
				28,
				20,
				30,
				60,
				35,
				200,
				40,
				500,
				45
			],
		},
	});

	map.value.addLayer({
		id: 'cluster-count',
		type: 'symbol',
		source: 'all',
		filter: ['all', ['has', 'point_count']],
		'paint': {
			'text-color': '#202',
			'text-halo-color': '#fff',
			'text-halo-width': 1
		},
		layout: {
			'text-field': '{point_count_abbreviated}',
			'text-font': [
				'Open Sans Semibold',
				'Arial Unicode MS Bold'
			],
			'text-size': 14,
			'icon-overlap': 'always',
			'icon-allow-overlap': true,
			'text-allow-overlap': true,
		},
	});

	handler = async (imei, evt) => {
		let posRef = posDB.get(imei)
		if (!posRef) {
			// TODO: Fix lazy reload fix.
			(await start()).features
			geoJsons.features = posDB.list()
			const src = map.value.getSource('all') as GeoJSONSource
			src.setData(geoJsons)
			posRef = posDB.get(imei)
			if (!posRef) {
				console.warn("no position ref");
			}
		}

		if ('longitude' in evt && 'latitude' in evt) {
			// @ts-ignore
			posRef.geometry.coordinates = [parseFloat(evt.longitude), parseFloat(evt.latitude)]
		}

		if ('angle' in evt) {
			// @ts-ignore
			posRef.properties.rotation = evt.angle
		}

		if ('speed' in evt) {
			// @ts-ignore
			posRef.properties.speed = evt.speed
		}

		const sauce = map.value.getSource('all') as GeoJSONSource
		sauce.updateData({
			update: [
				{
					id: posRef.id,
					newGeometry: {
						type: "Point",
						// @ts-ignore
						coordinates: posRef.geometry.coordinates,
					},
					addOrUpdateProperties: [
						{key: 'rotation', value: posRef.properties.rotation},
						{key: 'speed', value: posRef.properties.speed}
					],
				}
			]
		})
	};

	posEmitter.on('*', handler)

	// inspect a cluster on click
	map.value.on('click', 'clusters', async (e) => {
		const features = map.value.queryRenderedFeatures(e.point, {
			layers: ['clusters']
		});
		const clusterId = features[0].properties.cluster_id;
		// @ts-ignore
		const zoom = await map.value.getSource('all').getClusterExpansionZoom(clusterId);
		map.value.easeTo({
			// @ts-ignore
			center: features[0].geometry.coordinates,
			zoom: zoom + 2.2,
			duration: 500,
		});
	});

	map.value.on('mouseenter', 'assets', () => {
		map.value.getCanvas().style.cursor = 'pointer';
	});

	// Change it back to a pointer when it leaves.
	map.value.on('mouseleave', 'assets', () => {
		map.value.getCanvas().style.cursor = '';
	});

	map.value.on('click', 'assets', async (e) => {
		const features = map.value.queryRenderedFeatures(e.point, {
			layers: ['assets']
		});
		const hello = features[0];
		router.get(`/map/${encodeURIComponent(hello.properties['serial'])}`, { }, { preserveState: true })
	});

	mounted.value = true;
});

</script>

<template>
	<div class="wrapper">
		<slot v-if="mounted" />
	</div>
</template>
<style lang="scss">
.maplibregl-popup-anchor-bottom .maplibregl-popup-tip{
	border-top-color: #071426;
}
.maplibregl-popup-anchor-top-right .maplibregl-popup-tip{
	border-bottom-color: #071426;
}
.maplibregl-popup-anchor-top .maplibregl-popup-tip  {
	border-bottom-color: #071426;
}
.maplibregl-popup-anchor-top-left .maplibregl-popup-tip {
	border-bottom-color: #071426;
}
.maplibregl-popup-anchor-left .maplibregl-popup-tip {
	border-right-color: #071426;
}
.maplibregl-popup-anchor-right .maplibregl-popup-tip {
	border-left-color: #071426;
}

.maplibregl-popup-content {
	font-size: 1em;
	background: #071426;
	color: #FFF !important;
	border-radius: 10px;
}
</style>
<style lang="scss" scoped>
.marker-asset-none {
	background-image: url('/images/markers/marker-lt.png');
	background-size: cover;
	width: 30px;
	height: 41px;
}

.marker-asset-light_tower {
	background-image: url('/images/markers/marker-lt.png');
	background-size: cover;
	width: 30px;
	height: 41px;
}

.marker-asset-fuel_truck {
	background-image: url('/images/markers/marker-truck.png');
	background-size: cover;
	width: 30px;
	height: 41px;
}

.marker-asset-generator {
	background-image: url('/images/markers/marker-generator.png');
	background-size: cover;
	width: 30px;
	height: 41px;
}
</style>
