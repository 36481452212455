<script setup lang="ts">
import AppMenu from './AppMenu.vue';
import UserMenu from './../components/UserMenu.vue'
import { Link } from '@inertiajs/vue3'
import {inject} from "vue";
import {Config} from "@/utils/build";

const configuration = inject(Config);

</script>

<template>
    <div class="layout-sidebar flex-row">
		<div class="sidebar-header flex justify-content-center">
			<Link href="/map" class="app-logo">
				<img class="" :src="configuration.LogoURL" :alt="configuration.PlatformProvider" />
			</Link>
		</div>

	  <div class="layout-menu-container flex">
		<AppMenu />
	  </div>
	<!--<div class="flex align-items-center justify-content-center m-2">
	  <Link href="/management/trackers">
	  <Button icon="pi pi-box" class="flex-1" label="Unassigned Trackers"></Button>
	  </Link>
	</div>-->
	  <UserMenu></UserMenu>
    </div>
</template>

<style>
.app-logo img {
	padding: 13px 0;
	height: 50px;
}
.st0{fill:#262626;}
.st1{fill:#523180;}
.st2{fill:#FFFCF8;}
.st3{fill:#FFFFFF;}

.layout-dim .st0{
  fill:#F8F3EA;
}
.layout-dark .st0 {
  fill:#F8F3EA;
}

.layout-static .layout-sidebar {
	flex-direction: column !important;
}
.layout-static .sidebar-header {
}
.layout-static .sidebar-header {

}
</style>

<style lang="scss" scoped></style>
