import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useFilterScopeStore = defineStore('asset-scope', () => {
    const containers = ref<string[]>([]);
	const fuzzyMatch = ref('');
	const assetTypes = ref<string[]>([]);
	
    const trimmedFuzzyMatch = computed(() => fuzzyMatch.value.trim());

    const hasFuzzyFilter = computed(() => fuzzyMatch.value.trim().length > 0);
    const hasContainerFilter = computed(() => containers.value.map((c) => c.trim()).filter((c) => c.length > 0).length > 0);
	const hasTypeFilter = computed(() => assetTypes.value.length > 0);
	
	
	return { assetTypes, containers, fuzzyMatch, hasFuzzyFilter, hasContainerFilter, hasTypeFilter };
});
