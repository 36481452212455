<script setup lang="ts">
import { computed, ref, toRaw, toRef, watch } from "vue";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { useLayout } from "@/layout/composables/layout";
import Wrapper from "./Wrapper.vue";
import type { ContainerItem } from "@/utils/containers";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { Link } from "@inertiajs/vue3";
import { useAuthStore } from "@/store/auth";
import { capitalized } from "@/utils/strings";
import ContainerTreeSelect from "@/components/ContainerTreeSelect.vue";
import Chip from "primevue/chip";

//import { useRouter } from 'vue-router';
const confirmPopup = useConfirm();
const toast = useToast();

const authStore = useAuthStore();

(async () => {
    if (!authStore.userData) {
        await authStore.session();
    }
})();

const enableChangingSite = ref({});
const enableChangingRole = ref({});
const selectedSite = ref({});

const saving = ref({});

const props = defineProps<{
    users: [],
    containers: { [key: string]: ContainerItem },
    role: string,
}>();

const containerItems = toRef(props, 'containers');

const userRoles = ref({});
const options = ref([
    { name: "Administrator", value: 1 },
    { name: "Operator", value: 2 },
    { name: "User", value: 3 },
    { name: "Guest", value: 4 }
]);

const roleMap = {
    1: 'administrator',
    2: 'operator',
    3: 'user',
    4: 'guest'
}

const currentUsers = toRef(props, "users");

const saveSite = async (id: string) => {
    const siteId = Object.getOwnPropertyNames(selectedSite?.value?.[id] || {})?.[0]
    if (!siteId) {
        toast.add({
            severity: "error",
            summary: "Failed to update site for user"
        })

        return
    }

    saving.value[id] = true;

    const rawResponse = await fetch("?changesite=1", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "id": id,
            "site": siteId,
            "form": "change-site",
        })
    });

    saving.value[id] = false;
    if (rawResponse.ok) {
        await rawResponse.json()

        // @ts-ignore
        const identity = currentUsers.value.filter((r) => r.id == id)?.[0]

        if (identity) {
            // @ts-ignore
            identity.metadata_public.container_id = siteId
        }
        toast.add({
            severity: "info",
            summary: "User site has been updated"
        })

        enableChangingSite.value[id] = false
    } else {
        toast.add({
            severity: "error",
            summary: "Failed to update site for user"
        })
    }
}

const saveRole = async (id: string) => {
    const role = roleMap[userRoles.value[id].value]
    if (!role) {
        toast.add({
            severity: "error",
            summary: "Failed to update role for user"
        })

        return
    }

    saving.value[id] = true;

    const rawResponse = await fetch("?changerole=1", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "id": id,
            "role": role,
            "form": "change-role",
        })
    });

    saving.value[id] = false;

    if (rawResponse.ok) {
        await rawResponse.json()

        // @ts-ignore
        const identity = currentUsers.value.filter((r) => r.id == id)?.[0]

        if (identity) {
            // @ts-ignore
            identity.metadata_public.role = role
        }
        toast.add({
            severity: "info",
            summary: "User role has been updated"
        })

        enableChangingRole.value[id] = false
    } else {
        toast.add({
            severity: "error",
            summary: "Failed to update role for user"
        })
    }
}

watch(currentUsers, () => {
    for (const user of currentUsers.value) {
        // @ts-ignore
        const currentRole = user?.metadata_public?.role;
        if (!currentRole) {
            continue;
        }

        switch (currentRole) {
            case "administrator":
                // @ts-ignore
                userRoles.value[user.id] = { name: "Administrator", value: 1 };
                break;
            case "operator":
                // @ts-ignore
                userRoles.value[user.id] = { name: "Operator", value: 2 };
                break;
            case "user":
                // @ts-ignore
                userRoles.value[user.id] = { name: "User", value: 3 };
                break;
            case "guest":
                // @ts-ignore
                userRoles.value[user.id] = { name: "Guest", value: 4 };
                break;
            default:
            // code block
        }
    }
}, { immediate: true });

const idxMap = new Map<string, number>()

const changeSite = (id: string, index: number) => {
    if (!idxMap.has(id)) {
        idxMap.set(id, index)
    }
    if (id in enableChangingSite.value) {
        enableChangingSite.value[id] = !enableChangingSite.value[id]
    } else {
        enableChangingSite.value[id] = true
    }
}

const changeRole = (id: string, index: number) => {
    if (!idxMap.has(id)) {
        idxMap.set(id, index)
    }
    if (id in enableChangingRole.value) {
        enableChangingRole.value[id] = !enableChangingRole.value[id]
    } else {
        enableChangingRole.value[id] = true
    }
}

const { contextPath } = useLayout();
const tableRef = ref(null);
const filterTable = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
});

const formatDate = (value) => {
    return new Date(value).toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
    });
};

const menu = ref(null);

const toggleMenu = (event: Event) => {
    menu.value.toggle(event);
};


/*const confirm = (event: Event) => {
    confirmPopup.require({
        target: event.target,
        message: "This will send an email to the user. Are you sure?",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
            toast.add({
                severity: "info",
                summary: "Password Reset",
                detail: "An email will be sent off shortly asking user to set a new password.",
                life: 3000
            });
        }
    });
};*/
const editRole = ref(false);
const activateEditRole = (id: string) => {
    console.log(id);
    editRole.value = editRole.value!;
};

const isAdmin = computed(() => authStore?.userData?.identity?.metadata_public?.role === "administrator");
const selfUserId = computed(() => authStore?.userData?.identity?.id);
</script>

<template>
    <Wrapper>
        <Toast />

        <h1>Users</h1>

        <div v-if="role == 'user'">
            You do not have permission to see this page.
        </div>

        <DataTable v-if="role != 'user'"
            ref="tableRef"
            :value="currentUsers"
            paginator
            :rows="10"
            showCurrentPageReport
            responsiveLayout="scroll"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            :rowsPerPageOptions="[10, 25, 50]"
            :globalFilterFields="['traits.email']"
            v-model:filters="filterTable"
        >
            <template #header>
                <div class="flex flex-wrap gap-2 align-items-center justify-content-between">
                    <span class="p-input-icon-left w-full sm:w-20rem flex-order-1 sm:flex-order-0">
                        <i class="pi pi-search"></i>
                        <InputText v-model="filterTable.global.value" placeholder="Global Search" class="w-full" />
                    </span>
                    <Link href="/management/users/create" v-if="isAdmin">
                        <Button icon="pi pi-user-plus" class="p-button-outlined" label="Invite User"></Button>
                    </Link>
                </div>
            </template>
            <Column field="name" header="Email" headerClass="white-space-nowrap">
                <template #body="{ data }">
                    <span class="p-column-title">Name</span>
                    {{ data.traits.email }}
                </template>
            </Column>
            <Column field="status" header="Status" headerClass="white-space-nowrap">
                <template #body="{ data }">
                    <span class="p-column-title">Name</span>
                    <Tag :value="capitalized(data.state)" class="m-1"></Tag>
                    <Tag value="Invitation Sent" severity="warning" v-if="data.metadata_admin?.invite_jti"></Tag>
                </template>
            </Column>

            <Column header="Home Site" headerClass="white-space-nowrap">
                <template #body="{ data }">
                    <div v-if="enableChangingSite[data.id]" class="inline-flex align-items-center">
                        <!--<Chip label="asfasffasfsfsafsa" />
                        <Button icon="pi pi-check" class="p-button-rounded p-button-text p-button-secondary">
                            <i class="pi pi-pencil" style="font-size: 1rem"></i>
                        </Button>
                        -->
                        <ContainerTreeSelect :withDomain="true" v-model="selectedSite[data.id]" :single="true" placeholder="None selected" :containers="containerItems" />
                        <Button type="button" class="" label="" icon="pi pi-check" :loading="saving[data.id]" @click="saveSite(data.id)" />
                    </div>
                    <Chip v-if="!enableChangingSite[data.id] && userRoles[data.id]?.name != 'Administrator'" :label="containerItems?.[data.metadata_public.container_id]?.name" />
                </template>

            </Column>
            <Column header="Role" headerClass="white-space-nowrap">
                <template #body="{ data }">
                    <!--<Chip v-if="data.metadata_public.role" :label="data.metadata_public.role" />-->
                    <div v-if="enableChangingRole[data.id]" class="inline-flex align-items-center">
                        <SelectButton class="p-button-sm" v-model="userRoles[data.id]" :options="options" optionLabel="name"
                                      aria-labelledby="multiple" />
                        <Button type="button" class="" label="" icon="pi pi-check" :loading="saving[data.id]" @click="saveRole(data.id)" />
                    </div>
                    <!--<Button icon="pi pi-check" @click="activateEditRole(data.id)" class="p-button-rounded p-button-text p-button-secondary">
                        <i class="pi pi-pencil" style="font-size: 1rem"></i>
                    </Button>
                    -->
                    <Chip v-if="!enableChangingRole[data.id]" :label="userRoles[data.id]?.name" />
                </template>
            </Column>
            <Column field="actions" header="Actions" headerClass="white-space-nowrap">
                <template #body="{ data, index }">
                    <ConfirmPopup></ConfirmPopup>
                   <!--<Button ref="popup" @click="confirm($event)" size="small" v-if="isAdmin" icon="pi pi-check"
                            label="Reset Password" class="p-button-sm mr-2"></Button>-->
                    <Button v-if="isAdmin && userRoles[data.id]?.name != 'Administrator'" ref="popup" :disabled="enableChangingSite[data.id]" @click="changeSite(data.id, index)" size="small" icon="pi pi-box"
                            label="Change Site" class="p-button-sm mr-2"></Button>

                    <Button ref="popup" :disabled="enableChangingRole[data.id]" @click="changeRole(data.id, index)" size="small" v-if="isAdmin && data.id != selfUserId" icon="pi pi-users"
                            label="Change Role" class="p-button-sm mr-2"></Button>

                    <span class="p-buttonset">
 <!--
                        <Menu ref="menu" :model="overlayMenuItems" :popup="true" />
                        <Button type="button" label="Options" icon="pi pi-angle-down" @click="toggleMenu" style="width: auto" />

                       <Button label="Change Email Address"></Button>
                        <Button label="Change Name"></Button>
                        <Button label="Send Email">mailto:</Button>
                        -->
                    </span>
                </template>
            </Column>
        </DataTable>
    </Wrapper>
</template>
